<template>
  <b-row
    :class="[
      'name-title align-items-center ml-n2 mr-n2 pl-50 pr-50 pt-75 pb-75',
      { 'cursor-pointer': !isEditMode },
    ]"
    @click="onClickRow"
  >
    <b-col>
      <div
        v-if="isEditMode"
        v-click-outside="onClickOutside"
        @click.stop
      >
        <validation-observer ref="vo">
          <validation-provider
            #default="{ errors, failedRules }"
            :name="validationProviderName"
            rules="required"
          >
            <b-form-input
              v-model="nameTitle"
              :placeholder="placeholder"
              :state="errors.length ? false : null"
            />
            <small class="text-danger">
              <template v-if="failedRules.required">{{ failedRuleRequired }}</template>
              <template v-else>{{ errors[0] }}</template>
            </small>
          </validation-provider>
        </validation-observer>
      </div>
      <div
        v-else
        class="d-flex align-items-center"
      >
        <div
          class="d-flex align-items-center cursor-pointer"
          @click.stop="onClickEdit"
        >
          <h1 class="h4 mb-0">
            {{ value }}
            <feather-icon
              v-b-tooltip.hover.top="$t('Edit')"
              class="name-title__edit-icon"
              icon="Edit3Icon"
              role="button"
              size="20"
            />
          </h1>
        </div>
      </div>
    </b-col>
    <b-col
      v-if="!isEditMode"
      cols="auto"
    >
      <div
        :class="[
          'card-content__arrow',
          { 'collapsed': !isCardContent },
        ]"
      >
        <feather-icon
          size="20"
          icon="ChevronDownIcon"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  VBTooltip,
} from 'bootstrap-vue'
import directiveClickOutside from '@/directives/clickOutside'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'NameTitle',
  directives: {
    'b-tooltip': VBTooltip,
    clickOutside: directiveClickOutside,
  },
  components: {
    BRow,
    BCol,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: '',
    },
    isCardContent: {
      type: Boolean,
      required: true,
      default: true,
    },
    placeholder: {
      type: String,
      required: true,
      default: '',
    },
    failedRuleRequired: {
      type: String,
      required: true,
      default: '',
    },
    validationProviderName: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isEditMode: false,
      nameTitle: '',

      // validation rules
      required,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.nameTitle = newValue
      },
      immediate: true,
    },
  },
  methods: {
    onClickRow() {
      if (!this.isEditMode) {
        this.$emit('collapse')
      }
    },
    async onClickOutside() {
      if (this.nameTitle === this.value) {
        this.isEditMode = false
        return
      }

      const isValid = await this.$refs.vo.validate()
      if (!isValid) {
        return
      }

      this.isEditMode = false
      this.$emit('save', this.nameTitle)
    },
    onClickEdit() {
      setTimeout(() => {
        this.isEditMode = true
      })
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.name-title
  &:hover
    background-color: $body-bg
    .name-title
      &__edit-icon
        display: inline
  &__edit-icon
    display: none
    vertical-align: top
</style>
