<template>
  <b-row class="menu-content-offer">
    <b-col class="d-flex align-items-center">
      <div class="d-flex align-items-center flex-grow-1 mr-1">
        <div class="menu-content-offer__status-to-show-container">
          <div class="menu-content-offer__status-to-show">
            <icon-stop-list
              v-if="offer.item.isVisible && !offer.item.isEnabled"
              class="size-14 fill-danger"
            />
            <feather-icon
              v-if="!offer.item.isVisible"
              icon="EyeOffIcon"
              class="text-danger"
            />
          </div>
          <icon-move-vertical class="card-content__transparent" />
        </div>
        <b-link
          :to="{
            name: offer.item.type === 'MenuItem' ? 'menu-item' : 'combination-menu-item',
            params: { id: offer.item.id },
          }"
          class="mr-1"
        >
          {{ offer.item.name }}
        </b-link>
        <div class="card-content__transparent d-flex">
          <b-spinner
            v-if="isEnabledSaving"
            class="spinner-20 mr-50"
            variant="primary"
          />
          <icon-stop-list
            v-else
            v-b-tooltip.hover.top="$t('Temporary Unavailable')"
            :class="['size-20 mr-50', { 'fill-danger': !offer.item.isEnabled }]"
            role="button"
            @click.native="onClickIcon('isEnabled')"
          />
          <b-spinner
            v-if="isVisibleSaving"
            class="spinner-20"
            variant="primary"
          />
          <feather-icon
            v-else
            v-b-tooltip.hover.top="$t('Hide in Menus')"
            :icon="offer.item.isVisible ? 'EyeIcon' : 'EyeOffIcon'"
            :class="[{ 'text-danger': !offer.item.isVisible }]"
            role="button"
            size="20"
            @click="onClickIcon('isVisible')"
          />
        </div>
      </div>
      <validation-observer
        ref="vo"
        class="d-flex flex-shrink-0"
      >
        <offer
          :offer-id="offer.id"
          :offer-data="offerData"
          @updateValue="onUpdateValue"
          @save="onSave"
        />
      </validation-observer>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
    >
      <feather-icon
        :class="['card-content__transparent', { 'feather-disabled': !isContentValid }]"
        role="button"
        size="20"
        icon="XIcon"
        @click="$emit('delete', offer)"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import IconStopList from '@/components/icon/IconStopList.vue'
import IconMoveVertical from '@/components/icon/IconMoveVertical.vue'
import Offer from '@/components/Offer.vue'
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'

import mixinFormatter from '@/mixins/formatter'
import mixinRequests from '@/mixins/requests'
import mixinOffer from '@/mixins/offer'

import UPDATE_MENU_ITEM from '@/gql/mutation/menuItem/updateMenuItem.gql'

export default {
  name: 'MenuContentOffer',
  components: {
    BRow,
    BCol,
    BLink,
    BSpinner,
    IconStopList,
    IconMoveVertical,
    Offer,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinFormatter,
    mixinRequests,
    mixinOffer,
  ],
  props: {
    offer: {
      type: Object,
      required: true,
      default: () => {},
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      isEnabledSaving: false,
      isVisibleSaving: false,

      offerData: null,
    }
  },
  computed: {
    ...mapGetters({
      menus: 'menuManagement/menus',
    }),
  },
  created() {
    this.offerData = {
      measurementUnit: this.offer.measurementUnit,
      amount: this.offer.amount,
      price: this.getFormattedPrice(this.offer.price),
    }
  },
  methods: {
    ...mapActions({
      getMenus: 'menuManagement/getMenus',
      getMenuItems: 'menuManagement/getMenuItems',
      updateMenu: 'menuManagement/updateMenu',
    }),
    async onClickIcon(type) {
      this[`${type}Saving`] = true

      await this.$apollo.mutate({
        mutation: UPDATE_MENU_ITEM,
        variables: {
          updateMenuItemId: this.offer.item.id,
          [type]: !this.offer.item[type],
        },
      })

      await Promise.all([this.getMenus(), this.getMenuItems()])

      this.$emit(
        'updateOfferInMenuCategories',
        { ...this.offer, item: { ...this.offer.item, [type]: !this.offer.item[type] } },
      )

      this[`${type}Saving`] = false
    },
    // eslint-disable-next-line no-unused-vars
    onUpdateValue([offerId, fieldName, value]) {
      this.offerData = {
        ...this.offerData,
        [fieldName]: value,
      }
    },
    async onSave() {
      await this.$nextTick()

      const isValid = await this.$refs.vo.validate()
      if (!isValid) {
        return
      }

      // eslint-disable-next-line no-undef
      let categories = structuredClone(this.menus.find(i => i.id === this.$route.params.id).categories)
      const category = categories.find(i => i.items.find(j => j.id === this.offer.id))
      categories = categories.map(i => (i.id === category.id
        ? {
          ...i,
          items: i.items.map(j => (j.id === this.offer.id
            ? {
              ...j,
              price: this.offerData.price,
              amount: this.offerData.amount,
              measurementUnit: this.offerData.measurementUnit,
            }
            : j
          )),
        }
        : i
      ))
      this.updateMenu([this.$route.params.id, 'categories', categories])

      this.requests.push('updateOffer')
    },
  },
}
</script>

<style lang="sass">
.menu-content-offer
  &__status-to-show-container
    margin-right: 1rem
    position: relative
  &__status-to-show
    display: flex
    position: absolute
    top: 50%
    left: 0
    transform: translateY(-50%)
</style>
