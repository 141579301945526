<template>
  <div>
    <b-row>
      <b-col cols="auto">
        <b-card
          no-body
          style="height: calc(100% - 2rem)"
        >
          <div
            role="button"
            class="d-flex align-items-center back-btn h-100 pl-2 pr-2"
            @click="$emit('setTranslateMode', false)"
          >
            <feather-icon
              icon="ChevronLeftIcon"
              size="20"
            />
            <span class="h6 mb-0 ml-25 mr-50 font-weight-bolder">
              {{ $t('Back') }}
            </span>
          </div>
        </b-card>
      </b-col>
      <b-col>
        <b-card>
          <b-row>
            <b-col class="d-flex align-items-center">
              <h4 class="mb-0 title-dots">
                {{ title }}
              </h4>
            </b-col>
            <b-col
              cols="auto"
              class="d-flex align-items-center"
            >
              <b-link @click="onClickAddLanguage">
                {{ $t('Add language') }}
              </b-link>
              <div
                v-if="translations.length"
                class="translations-list"
              >
                <div
                  v-for="translation in translations"
                  :key="translation.language"
                  class="translations-list__item"
                >
                  <b-tooltip
                    v-if="!translation.isEnabled"
                    :target="`language_${translation.language}`"
                  >
                    <b-link :to="{ name: 'billing' }">
                      {{ $t('Upgrade') }}
                    </b-link>
                    {{ $t('your subscription to enable additional languages') }}
                  </b-tooltip>
                  <b-badge
                    :id="`language_${translation.language}`"
                    :variant="translation.isEnabled ? translation.language === translationActive ? 'primary' : 'light-primary' : 'light-secondary'"
                    :style="{'cursor': translation.isEnabled && translation.language !== translationActive ? 'pointer' : 'default'}"
                    @click="setTranslationActive(translation.isEnabled, translation.language)"
                  >
                    {{ translation.language }}
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="6"
        class="translation-col"
      >
        <b-card>
          <h4 class="mb-0 dots">
            {{
              primaryLanguage
                ? primaryLanguage.longName
                : getLanguageLongName(restaurant ? restaurant.primaryLanguage : '')
            }}
          </h4>
        </b-card>
        <slot />
      </b-col>
      <b-col
        v-if="translations.length && translations.find(i => i.isEnabled)"
        md="6"
        class="translation-col"
      >
        <b-card>
          <b-row>
            <b-col>
              <h4 class="mb-0 title-dots">
                {{ getLanguageLongName(translationActive) }}
              </h4>
            </b-col>
            <b-col cols="auto">
              <icon-translate
                v-if="isSubscriptionPremium"
                v-b-tooltip.hover.top="$t('Auto Translate')"
                class="size-18 mr-2"
                role="button"
                @click.native="$emit('translate')"
              />
              <feather-icon
                v-b-tooltip.hover.top="$t('Clear')"
                icon="XCircleIcon"
                size="20"
                role="button"
                @click="$emit('clearTranslation')"
              />
            </b-col>
          </b-row>
        </b-card>
        <slot
          name="translation"
          :translation="translations.find(i => i.language === translationActive)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BCol,
  BRow,
  BTooltip,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import IconTranslate from '@/components/icon/IconTranslate.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Translate',
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BTooltip,
    BLink,
    IconTranslate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    translations: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    translationActive: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
    primaryLanguage: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      languages: 'dictionaries/languages',
      restaurant: 'restaurant/restaurant',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
  },
  methods: {
    setTranslationActive(isTranslationEnabled, language) {
      if (isTranslationEnabled) {
        this.$emit('setTranslationActive', language)
      }
    },
    getLanguageLongName(shortName) {
      let result = this.$t('Language')

      if (this.languages.length) {
        const language = this.languages.find(i => i.shortName === shortName)

        if (language) {
          result = language.longName
        }
      }

      return result
    },
    onClickAddLanguage() {
      if (this.$route.name === 'settings') {
        this.$emit('addLanguage', '#languages')
      } else {
        this.$router.push({ name: 'settings', hash: '#languages' })
      }
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  .translations-list
    display: flex
    flex-wrap: wrap
    margin-left: 2rem
    &__item
      margin-right: 10px
      &:last-child
        margin-right: 0
      .badge
        text-transform: capitalize
        padding: 8px 10px
        font-size: 12px

  .translation-col
    &:nth-child(even)
      border-left: 1px solid $border-color
      &:after
        content: ''
        display: block
        position: absolute
        bottom: 0
        left: -1px
        height: 28px
        width: 1px
        background-color: $body-bg
</style>
