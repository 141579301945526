<template>
  <validation-observer
    ref="vo"
    class="flex-grow-1"
  >
    <validation-provider
      #default="{ errors }"
      name="category_name"
      rules="required"
    >
      <b-form-input
        v-model="name"
        :class="[inputClass]"
        :placeholder="$t('Category Name')"
        :state="errors.length ? false : null"
        @blur="onBlurCategoryName('vo')"
      />
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { BFormInput } from 'bootstrap-vue'

export default {
  name: 'CategoryName',
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormInput,
  },
  props: {
    categoryId: {
      type: String,
      required: true,
      default: '',
    },
    categoryName: {
      type: String,
      required: true,
      default: '',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      name: '',
    }
  },
  watch: {
    categoryName: {
      handler(newValue) {
        this.name = newValue
      },
      immediate: true,
    },
  },
  methods: {
    async onBlurCategoryName(refName) {
      if (this.name === this.categoryName) {
        return
      }

      const isValid = await this.$refs[refName].validate()
      if (!isValid) {
        return
      }

      this.$emit('patchCategoryName', this.name)
    },
  },
}
</script>
