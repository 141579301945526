<template>
  <div :class="['input-select', { 'input-select_size-sm': size === 'sm' }]">
    <b-input-group
      :prepend="prepend"
      :append="append"
      :size="size"
      :class="[{ 'input-group-merge': inputGroupMerge }]"
    >
      <slot name="prepend" />
      <b-form-input
        ref="input"
        v-model="searchInput"
        :class="['input-select__input', { 'is-invalid': isError }]"
        :placeholder="placeholder"
        autocomplete="off"
        :disabled="disabled"
        :autofocus="autofocus"
        :style="errorStyle"
        :size="size"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @keyup.enter="onEnter"
        @keyup.esc="onEsc"
        @keyup.up="onKeyArrow(-1)"
        @keyup.down="onKeyArrow(1)"
        @keydown.tab.exact="onKeydown('keydown:tab', $event)"
        @keydown.shift.tab.exact="onKeydown('keydown:shift:tab', $event)"
      />
      <transition name="error-message">
        <small
          v-if="isError && errorMessage"
          ref="error"
          :class="['error text-danger', { 'right-0': prepend }]"
        >
          {{ errorMessage }}
        </small>
      </transition>
      <b-input-group-append
        v-if="clearable"
        is-text
      >
        <feather-icon
          icon="XIcon"
          role="button"
          @click="onClear"
        />
      </b-input-group-append>
      <slot name="append" />
    </b-input-group>
    <ul
      v-if="filteredOptions.length"
      class="vs__dropdown-menu"
      @mouseenter="selecting = true"
      @mouseleave="selecting = false"
    >
      <li
        v-for="option in filteredOptions"
        :key="option.id"
        :class="['vs__dropdown-option', { 'vs__dropdown-option--selected': option === current }]"
        @click="onClickOption(option)"
      >
        {{ option[label] }}
      </li>
    </ul>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
} from 'bootstrap-vue'

export default {
  name: 'InputSelect',
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    value: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: 'label' },
    options: { type: Array, required: false, default: () => [] },
    placeholder: { type: String, required: false, default: '' },
    autofocus: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    clearable: { type: Boolean, required: false, default: false },
    clearOnSelect: { type: Boolean, required: false, default: false },
    inputGroupMerge: { type: Boolean, required: false, default: false },
    keepOpen: { type: Boolean, required: false, default: false },
    filterSelection: { type: Boolean, required: false, default: false },
    exact: { type: Boolean, required: false, default: false },
    append: { type: String, required: false, default: null },
    prepend: { type: String, required: false, default: null },
    isError: { type: Boolean, required: false, default: false },
    errorMessage: { type: String, required: false, default: 'Invalid Input' },
    size: { type: String, required: false, default: null },
    findBy: {
      type: Function,
      required: false,
      default(option, search) {
        return option[this.label].toLocaleLowerCase().includes(search)
      },
    },
  },
  data() {
    return {
      searchInput: '',
      savedValue: '',
      idx: -1,
      selecting: false,
      active: false,
      errorStyle: null,
    }
  },
  computed: {
    search() {
      return this.searchInput.trim().toLocaleLowerCase()
    },
    current() {
      return this.filteredOptions[this.idx]
    },
    selectOptions() {
      if (!this.active || this.disabled) {
        return []
      }
      return this.options
    },
    filteredOptions() {
      return this.filterSelection && this.search
        ? this.selectOptions.filter(i => this.findBy(i, this.search))
        : this.selectOptions
    },
  },
  watch: {
    value(v) {
      this.searchInput = v
    },
    isError() {
      this.getErrorStyle()
    },
    errorMessage() {
      this.getErrorStyle()
    },
    filteredOptions() {
      this.idx = this.filteredOptions.findIndex(i => this.findBy(i, this.search))
    },
    search() {
      this.idx = this.exact
        ? this.filteredOptions.findIndex(i => i[this.label].trim().toLocaleLowerCase() === this.search)
        : this.filteredOptions.findIndex(i => this.findBy(i, this.search))
    },
  },
  mounted() {
    this.searchInput = this.value
    this.getErrorStyle()
  },
  methods: {
    onKeyArrow(direction = 0) {
      if (!this.active) {
        this.toggleSelector(true)
        return
      }
      let i = this.idx
      const size = this.filteredOptions.length
      if (size) {
        if (i === -1) {
          i = direction > 0 ? 0 : size - 1
        } else {
          i = (i + direction + size) % size
        }
        this.idx = i
      }
    },
    onEsc(event) {
      if (this.active) {
        this.$emit('input', this.savedValue)
        this.toggleSelector()
      } else {
        event.target.blur()
      }
    },
    getMatchingOption() {
      if (this.current) {
        return this.current
      }
      if (this.search) {
        return { [this.label]: this.searchInput.trim() }
      }
      return null
    },
    onEnter() {
      let opt
      if (this.active) {
        opt = this.getMatchingOption()
        if (opt) {
          this.$emit('select', opt)
          if (this.clearOnSelect) {
            this.searchInput = ''
          }
        }
      }
      this.toggleSelector()
    },
    onKeydown(eventName, value) {
      this.$emit(eventName, value)
      this.active = false
    },
    onInput(v) {
      this.active = true
      this.$emit('input', v)
    },
    onClickOption(v) {
      this.selecting = false
      this.$emit('input', v[this.label])
      this.$emit('select', v)
      if (this.clearOnSelect) {
        this.searchInput = ''
      }

      this.onBlur()
    },
    focus() {
      this.$refs.input.$el.focus()
    },
    onFocus(event) {
      this.active = true
      if (this.value !== undefined) {
        this.savedValue = this.value
      } else {
        this.searchInput = ''
      }
      event.target.select()
      this.$emit('focus')
    },
    onBlur() {
      if (!this.selecting) {
        this.toggleSelector(false)
        this.$emit('blur')
      }
    },
    onClear() {
      this.searchInput = ''
      this.$emit('clear')
    },
    toggleSelector(v) {
      this.active = this.keepOpen || (v !== undefined ? v : !this.active)
    },
    async getErrorStyle() {
      await this.$nextTick()
      const input = this.$refs.input?.$el
      this.errorStyle = null
      if (this.isError && this.errorMessage && input) {
        const ih = window.getComputedStyle(input).height
        const eh = window.getComputedStyle(this.$refs.error).height
        this.errorStyle = {
          height: `calc(${ih} + ${eh})`,
          'padding-top': 0,
          'padding-bottom': eh,
        }
      }
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/vue/libs/vue-select.scss'

.input-select
  position: relative
  &_hide-input
    .form-control
      background-color: transparent
      color: transparent
  &_size-sm
    .vs__dropdown-option
      font-size: 0.9rem
      padding: 0.375rem 1rem
  &__input
    transition: padding 0.25s ease, height 0.25s ease
  .vs__dropdown-menu
    top: calc(100% + 0.25rem)
  .vs__dropdown-option
    &:not(.vs__dropdown-option--selected):hover
      background-color: $vs-state-active-bg
      color: $vs-state-active-color

  .error
    position: absolute
    bottom: 1px
    left: 1rem
    z-index: 5
  .error-message-enter, .error-message-leave-to
    opacity: 0
  .error-message-enter-active, .error-message-leave-active
    transition: opacity 0.25s
  .right-0
    right: 0
</style>
