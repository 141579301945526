var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{class:[
    'name-title align-items-center ml-n2 mr-n2 pl-50 pr-50 pt-75 pb-75',
    { 'cursor-pointer': !_vm.isEditMode } ],on:{"click":_vm.onClickRow}},[_c('b-col',[(_vm.isEditMode)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],on:{"click":function($event){$event.stopPropagation();}}},[_c('validation-observer',{ref:"vo"},[_c('validation-provider',{attrs:{"name":_vm.validationProviderName,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
  var failedRules = ref.failedRules;
return [_c('b-form-input',{attrs:{"placeholder":_vm.placeholder,"state":errors.length ? false : null},model:{value:(_vm.nameTitle),callback:function ($$v) {_vm.nameTitle=$$v},expression:"nameTitle"}}),_c('small',{staticClass:"text-danger"},[(failedRules.required)?[_vm._v(_vm._s(_vm.failedRuleRequired))]:[_vm._v(_vm._s(errors[0]))]],2)]}}],null,false,3572993245)})],1)],1):_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex align-items-center cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.onClickEdit($event)}}},[_c('h1',{staticClass:"h4 mb-0"},[_vm._v(" "+_vm._s(_vm.value)+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('Edit')),expression:"$t('Edit')",modifiers:{"hover":true,"top":true}}],staticClass:"name-title__edit-icon",attrs:{"icon":"Edit3Icon","role":"button","size":"20"}})],1)])])]),(!_vm.isEditMode)?_c('b-col',{attrs:{"cols":"auto"}},[_c('div',{class:[
        'card-content__arrow',
        { 'collapsed': !_vm.isCardContent } ]},[_c('feather-icon',{attrs:{"size":"20","icon":"ChevronDownIcon"}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }