<template>
  <b-card>
    <b-row>
      <b-col class="d-flex align-items-center">
        <b-card-title class="mb-0">
          {{ info === 'menu' ? $t('schedulesTitleMenu') : $t('schedulesTitleEvent') }}
        </b-card-title>
        <feather-icon
          icon="InfoIcon"
          role="button"
          size="20"
          class="ml-50 text-primary"
          @click.stop="setIsInfo([info, 'schedules'])"
        />
      </b-col>
      <b-col
        v-if="!schedules.length"
        cols="auto"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          class="ml-2"
          style="min-height: 30px"
          :disabled="isScheduleCreating"
          @click="onClickAddSchedule"
        >
          <b-spinner
            v-if="isScheduleCreating"
            variant="primary"
            class="d-flex"
            small
          />
          <template v-else>
            {{ $t('scheduleAdd') }}
          </template>
        </b-button>
      </b-col>
    </b-row>
    <b-collapse v-model="isInfo[info].schedules">
      <small class="d-block mt-2">
        {{ info === 'menu' ? $t('infoSchedulesMenu') : $t('infoSchedulesEvent') }}
      </small>
    </b-collapse>
    <app-collapse
      v-if="schedules.length"
      accordion
      hover
      type="border"
      class="mt-2 mb-2"
    >
      <schedule
        v-for="schedule in schedules"
        :key="schedule.id"
        :schedule-fetched="schedule"
        @delete="onDelete"
      />
    </app-collapse>
    <b-button
      v-if="schedules.length"
      variant="outline-primary"
      :disabled="isScheduleCreating"
      size="sm"
      style="min-height: 30px"
      @click="onClickAddSchedule"
    >
      <b-spinner
        v-if="isScheduleCreating"
        variant="primary"
        class="d-flex"
        small
      />
      <template v-else>
        {{ $t('scheduleAdd') }}
      </template>
    </b-button>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCollapse,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import Schedule from '@/components/Schedule.vue'

import CREATE_SCHEDULE from '@/gql/mutation/schedule/createSchedule.gql'
import DELETE_SCHEDULE from '@/gql/mutation/schedule/deleteSchedule.gql'

export default {
  name: 'CardSchedules',
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCollapse,
    BButton,
    BSpinner,
    AppCollapse,
    Schedule,
  },
  props: {
    info: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isScheduleCreating: false,
      schedules: [],
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      isInfo: 'main/isInfo',
      isSchedulesFetched: 'schedules/isSchedulesFetched',
      schedulesFromStore: 'schedules/schedules',
    }),
  },
  watch: {
    isSchedulesFetched() {
      this.setData()
    },
    $route: {
      immediate: true,
      handler() {
        this.setData()
      },
    },
  },
  methods: {
    ...mapActions({
      setIsInfo: 'main/setIsInfo',
      setSchedules: 'schedules/setSchedules',
    }),
    setData() {
      if (!this.isSchedulesFetched) {
        return
      }
      this.schedules = this.schedulesFromStore
        .filter(i => i[this.$route.name] && i[this.$route.name].id === this.$route.params.id)
    },
    async onClickAddSchedule() {
      this.isScheduleCreating = true

      const response = await this.$apollo.mutate({
        mutation: CREATE_SCHEDULE,
        variables: {
          restaurant: this.restaurant.id,
          name: this.$t('scheduleNameDefault'),
          [this.$route.name]: this.$route.params.id,
        },
      })

      // eslint-disable-next-line no-undef
      this.schedules = [...this.schedules, structuredClone(response.data.createSchedule)]

      // eslint-disable-next-line no-undef
      this.setSchedules([...this.schedulesFromStore, structuredClone(response.data.createSchedule)])

      // open panel with posted schedule
      await this.$nextTick()
      const accordion = this.$children.find(i => i.accordion)
      const accordionLastSchedule = accordion.$children[this.$children.find(i => i.accordion).$children.length - 1]
      const accordionLastScheduleCollapse = accordionLastSchedule.$children[accordionLastSchedule.$children.length - 1]
      accordionLastScheduleCollapse.updateVisible(true)

      this.isScheduleCreating = false
    },
    async onDelete(id) {
      this.schedules = this.schedules.filter(i => i.id !== id)
      this.setSchedules(this.schedulesFromStore.filter(i => i.id !== id))

      await this.$apollo.mutate({
        mutation: DELETE_SCHEDULE,
        variables: {
          deleteScheduleId: id,
        },
      })
    },
  },
}
</script>
