import { mapGetters } from 'vuex'

import CREATE_OFFER from '@/gql/mutation/offer/createOffer.gql'
import UPDATE_OFFER from '@/gql/mutation/offer/updateOffer.gql'
import DELETE_OFFER from '@/gql/mutation/offer/deleteOffer.gql'

export default {
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      measurementUnitParams: 'restaurant/measurementUnitParams',
    }),
  },
  methods: {
    createOffer(id) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_OFFER,
          variables: {
            restaurant: this.restaurant.id,
            item: id,
            price: null,
            amount: null,
            measurementUnit: this.measurementUnitParams.length ? this.measurementUnitParams[0] : '',
          },
        }),
      ]
    },
    updateOffer() {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_OFFER,
          variables: {
            updateOfferId: this.offer.id,
            price: this.offerData.price ? +this.offerData.price : null,
            amount: this.offerData.amount ? +this.offerData.amount : null,
            measurementUnit: this.offerData.measurementUnit,
          },
        }),
      ]
    },
    deleteOffer(id) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_OFFER,
          variables: {
            deleteOfferId: id,
          },
        }),
      ]
    },
  },
}
