export default {
  data() {
    return {
      isRequestsInProgress: false,
      requests: [],
    }
  },
  watch: {
    async requests(newValue) {
      if (newValue.length && !this.isRequestsInProgress) {
        this.isRequestsInProgress = true

        const func = newValue[0]
        switch (typeof func) {
          case 'string':
            await Promise.all(this[func]())
            break
          case 'function':
            await Promise.all(func())
            break
          default:
            // eslint-disable-next-line no-console
            console.error('Invalid request type')
        }

        this.isRequestsInProgress = false
        this.requests.splice(0, 1)
      }
    },
  },
}
