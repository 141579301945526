<template>
  <div
    ref="root"
    :class="[
      'textarea-counter',
      {
        'textarea-counter_sm': size === 'sm',
        'textarea-counter_active': isInFocus || isError,
      },
    ]"
  >
    <b-form-textarea
      class="textarea-counter__field"
      :size="size"
      no-resize
      :value="value"
      :disabled="disabled"
      :placeholder="placeholder"
      :state="isError ? false : null"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <div :class="['textarea-counter__limit', { 'text-danger': isError }]">
      <small>{{ value.length }} / {{ textLimit }}</small>
    </div>
  </div>
</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'TextareaCounter',
  components: {
    BFormTextarea,
  },
  props: {
    size: {
      type: String,
      required: true,
      default: '' || 'sm', // '' is default size value
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    placeholder: {
      type: String,
      required: true,
      default: '',
    },
    isError: {
      type: Boolean,
      required: true,
      default: false,
    },
    textLimit: {
      type: Number,
      required: true,
      default: 10,
    },
    value: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      isInFocus: false,
    }
  },
  watch: {
    value(newValue) {
      this.updateDatasetInRootElement(newValue)
    },
  },
  methods: {
    updateDatasetInRootElement(value) {
      this.$refs.root.dataset.textareaValue = `${value}\n`
    },

    onInput(event) {
      this.$emit('input', event)
    },
    onFocus() {
      this.updateDatasetInRootElement(this.value)
      this.isInFocus = true
    },
    onBlur() {
      this.$emit('blur')
      this.isInFocus = false
    },
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.textarea-counter
  display: grid
  position: relative
  &::after
    content: attr(data-textarea-value)
    font-size: $font-size-base
    padding: 0.8rem 1rem
    line-height: 1.6rem
    border: 1px solid transparent
    letter-spacing: normal
    white-space-collapse: preserve
    text-wrap: wrap
    overflow-wrap: break-word
    visibility: hidden
    grid-area: 1 / 1 / 2 / 2
    transition: padding-bottom 0.25s ease-in-out
  &_sm
    &::after
      font-size: $small-font-size
      padding: 0.4rem 0.857rem
    .textarea-counter__limit
      bottom: 0.4rem
      right: 0.857rem
  &_active
    &::after
      padding-bottom: calc(0.8rem * 2.5)
    &.textarea-counter_sm::after
      padding-bottom: calc(0.4rem * 4)
    .textarea-counter__limit
      opacity: 1
      visibility: visible
  &__field
    grid-area: 1 / 1 / 2 / 2
  &__limit
    position: absolute
    bottom: 0.8rem
    right: 1rem
    line-height: 1
    opacity: 0
    visibility: hidden
    transition-duration: 0.25s
    transition-property: visibility, opacity
    transition-timing-function: ease-in-out
</style>
