/* eslint no-param-reassign: ["error", { "props": false }] */
import { generateQRImage, setQRcolor } from '@/qr/qrcodeGen'

import { createBackgroundPattern, setBackgroundColor } from '@/qr/background'

import svgShapes from '@/qr/svg-shapes.json'
import { transformImage, draggable, setSelectionHandles } from '@/qr/DragRotateScale'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { embeddGoogleFonts, loadFontFace } from '@/pdm/google-fonts'
import {
  getBoxSize, getMarginSize, roundSize, convertSize, createElementSVG,
} from './utils'

pdfMake.vfs = pdfFonts.pdfMake.vfs

let usedGoogleFonts
let abortController

const domUrl = window.URL || window.webkitURL || window

function setItemFrame(layer, element) {
  let frame = element.querySelector('svg#frame')
  const content = (frame && frame.querySelector('svg')) || element.firstElementChild

  if (layer.frame.active === false) { // remove frame
    if (frame) frame.remove()
    content.removeAttribute('width')
    content.removeAttribute('height')
    content.removeAttribute('x')
    content.removeAttribute('y')
    element.prepend(content)
  } else {
    const vbox = content.viewBox.baseVal
    const unit = (vbox.width + vbox.height) / 200 // 1% of the avergage between h and w
    const { width, offset } = layer.frame

    const frameOffset = offset * unit
    const frameWidth = width * unit
    const d = frameOffset + frameWidth
    const w = vbox.width + 2 * frameOffset
    const h = vbox.height + 2 * frameOffset
    const W = w + 2 * frameWidth
    const H = h + 2 * frameWidth

    content.setAttribute('width', vbox.width)
    content.setAttribute('height', vbox.height)
    content.setAttribute('x', d)
    content.setAttribute('y', d)
    const viewBox = [0, 0, W, H].join(' ')
    const pathD = `M0 0h${W}v${H}h${-W}v${-H}M${frameWidth} ${frameWidth}v${h}h${w}v${-h}h${-w}`

    if (frame) {
      const p = frame.lastElementChild
      p.setAttribute('d', pathD)
      frame.setAttribute('viewBox', viewBox)
    } else {
      frame = createElementSVG('svg', { id: 'frame', viewBox, preserveAspectRatio: 'xMinYMin meet' })
      const p = createElementSVG('path', { d: pathD, fill: layer.qr ? layer.color.stroke : layer.color.fill })
      if (layer.color.opacity !== undefined) p.setAttribute('opacity', layer.color.opacity)
      frame.appendChild(content)
      frame.appendChild(p)
      element.appendChild(frame)
    }
  }

  const handles = element.querySelector('g#handle')
  if (handles) {
    handles.remove()
    setSelectionHandles(handles, element)
    element.appendChild(handles)
  }
}

function displayGridLines(svg, lines, color) {
  const b = svg.viewBox.baseVal
  const w = b.width
  const h = b.height
  const s = (w < h ? w : h) / (lines || 1)

  const g = createElementSVG('g', { id: 'grid-lines', stroke: color })
  const defs = createElementSVG('defs')
  const pattern = createElementSVG('pattern', {
    id: 'grid_pat',
    width: s,
    height: s,
    'stroke-width': lines / 4,
    viewBox: '0 0 100 100',
    patternUnits: 'userSpaceOnUse',
  })
  pattern.appendChild(createElementSVG('path', { d: 'M0 5v90M5 0h90' }))
  const rect = createElementSVG('rect', {
    width: '100%',
    height: '100%',
    fill: 'url(#grid_pat)',
  })
  defs.appendChild(pattern)
  g.appendChild(defs)
  g.appendChild(rect)
  svg.appendChild(g)
}

function unselectElement(selected) {
  if (selected?.layer) selected.layer.selected = false
  if (selected?.el) {
    const handle = selected.el.querySelector('#handle')
    if (handle) selected.el.removeChild(handle)
    selected.el.classList.remove('selected')
    selected.el.classList.add('selectable')
    if (abortController) abortController.abort()
    delete selected.layer
    delete selected.el
  }
}

function selectElement(opt, element) {
  if (!opt.selected) opt.selected = {}

  if (element !== opt.selected?.el) {
    unselectElement(opt.selected) // unselect currently selected
    element.classList.remove('selectable')
    element.classList.add('selected')
    opt.selected.layer ||= opt.document.content.find(l => l.idx === element.dataset.idx)
    opt.selected.layer.selected = true
    opt.selected.el = element
    if (opt.selected.layer.transform && !opt.selected.layer.background) abortController = draggable(opt)
  }
}

async function loadImage(layer, userMedia) {
  let src
  let isSvg
  if (layer.img.isUserMedia) {
    const media = userMedia.find(i => i.name === layer.img.file_name)
    if (!media || !media.data) {
      return null
    }
    src = media.data
    isSvg = media.isSvg
  } else {
    src = svgShapes[layer.img.file_name]
    isSvg = true
  }

  const g = createElementSVG('g', { cursor: 'pointer' })

  if (isSvg) {
    g.innerHTML = src
    const svg = g.firstElementChild
    svg.removeAttribute('width')
    svg.removeAttribute('height')
    svg.setAttribute('preserveAspectRatio', 'xMinYMin meet')
    layer.color ||= { opacity: 1 }

    if (svg.hasAttribute('fill')) layer.color.fill ||= svg.getAttribute('fill')

    if (layer.color.fill && svg.hasAttribute('fill-opacity') && layer.color.fillOpacity === undefined) { layer.color.fillOpacity = parseFloat(svg.getAttribute('fill-opacity')) }

    if (svg.hasAttribute('stroke')) layer.color.stroke ||= svg.getAttribute('stroke')

    if (layer.color.stroke && svg.hasAttribute('stroke-opacity') && layer.color.strokeOpacity === undefined) { layer.color.strokeOpacity = parseFloat(svg.getAttribute('stroke-opacity')) }

    if (layer.color.stroke && svg.hasAttribute('stroke-width') && layer.color.strokeWidth === undefined) { layer.color.strokeWidth = parseInt(svg.getAttribute('stroke-width'), 10) * 10 }
  } else {
    // PNG or JPEG
    const img = createElementSVG('image', {
      href: src, width: '100%', height: '100%', title: layer.img.file_name,
    })
    const svg = createElementSVG('svg', { preserveAspectRatio: 'xMinYMin meet' })
    const i = new Image()
    i.src = src
    await img.decode()
    svg.setAttribute('viewBox', `0 0 ${i.naturalWidth} ${i.naturalHeight}`)
    svg.appendChild(img)
    g.appendChild(svg)
  }
  const title = createElementSVG('title')
  title.textContent = layer.img.file_name
  g.firstElementChild.prepend(title)
  return g
}

function setColor(layer, g) {
  if (layer.background) {
    setBackgroundColor(layer, g)
  } else {
    const frame = g.querySelector('svg#frame')
    const svg = frame ? frame.querySelector('svg') : g.firstElementChild
    if (frame) {
      const path = frame.lastElementChild
      path.setAttribute('fill', layer.qr ? layer.color.stroke : layer.color.fill)
      if (layer.color.opacity !== undefined) path.setAttribute('opacity', layer.color.opacity)
    }

    if (layer.qr) setQRcolor(layer, svg)
    else {
      if (layer.color.fill) {
        svg.setAttribute('fill', layer.color.fillOpacity === 0 ? 'none' : layer.color.fill)
        if (layer.color.fillOpacity) svg.setAttribute('fill-opacity', layer.color.fillOpacity)
      }
      if (layer.color.stroke) {
        svg.setAttribute('stroke', layer.color.strokeOpacity === 0 ? 'none' : layer.color.stroke)
        if (layer.color.strokeOpacity) svg.setAttribute('stroke-opacity', layer.color.strokeOpacity)

        if (layer.color.strokeWidth !== undefined) svg.setAttribute('stroke-width', layer.color.strokeWidth / 10)
      }
      if (layer.color.opacity !== undefined) svg.setAttribute('opacity', layer.color.opacity)
    }
  }
}

async function createText(layer, destination) {
  if (!layer.txt.value && destination !== 'preview') return null

  // For earlier designs convet the old style individual font features
  // to the new style common object. This should not effect new designs
  if (!layer.txt.font) {
    const t = layer.txt
    layer.txt.font = {
      family: t.font_family, style: t.font_style, weight: t.font_weight,
    }
    delete t.font_family
    delete t.font_size
    delete t.font_style
    delete t.font_weight
  }
  delete layer.txt.font.size

  if (!usedGoogleFonts[layer.txt.font.family]) {
    usedGoogleFonts[layer.txt.font.family] = { style: new Set(), weight: new Set(), text: [] }
  }
  usedGoogleFonts[layer.txt.font.family].style.add(layer.txt.font.style)
  usedGoogleFonts[layer.txt.font.family].weight.add(layer.txt.font.weight)

  const txt = usedGoogleFonts[layer.txt.font.family].text
  usedGoogleFonts[layer.txt.font.family].text = [...new Set(txt.concat(...layer.txt.value))]

  await loadFontFace(layer.txt.font)
  const t = createElementSVG('text', {
    'font-size': 16,
    'font-family': layer.txt.font.family,
    'font-style': layer.txt.font.style,
    'font-weight': layer.txt.font.weight,
    x: '50%',
    y: '50%',
    'dominant-baseline': 'middle',
    'text-anchor': 'middle',
    cursor: 'pointer',
    // textLength: `${layer.txt.value.length}em`,
  })
  t.style['user-select'] = 'none'
  t.style['white-space'] = 'pre'

  t.appendChild(document.createTextNode(layer.txt.value))

  const svg = createElementSVG('svg', { preserveAspectRatio: 'xMinYMin meet' })
  svg.appendChild(t)

  document.body.appendChild(svg) // mount the SVG to the DOM to get it's size
  const bbox = t.getBBox()
  svg.setAttribute('viewBox', `0 0 ${bbox.width} ${bbox.height}`)

  const g = createElementSVG('g')
  g.appendChild(svg)

  if (destination === 'preview') {
    const rObserver = new ResizeObserver((observable, thisObserver) => {
      const { target } = observable[0]
      const parent = target.parentElement
      const grandParent = parent.parentElement
      document.body.appendChild(parent)
      const box = target.getBBox()
      parent.setAttribute('viewBox', `0 0 ${box.width} ${box.height}`)
      grandParent.prepend(parent)

      setItemFrame(layer, g)
      transformImage(g, layer.transform, destination)
      thisObserver.disconnect()
    })

    const mObserver = new MutationObserver(() => {
      rObserver.observe(t)
    })
    rObserver.observe(t)
    mObserver.observe(t, { attributes: true, childList: true })
  }

  return g
}

function constructCanvas(opt, unit, destination) {
  const imgSize = getBoxSize(opt.document.image.size, unit)

  if (destination !== 'download' && opt.document.image.bleedOrTrim < 0) {
    const size = imgSize.width < imgSize.height ? imgSize.width : imgSize.height
    const value = convertSize(opt.document.image.bleedOrTrim, opt.document.image.size.unit, unit)
    const k = (size - 2 * value) / size
    imgSize.width *= k
    imgSize.height *= k
  }
  imgSize.width = roundSize(imgSize.width, unit, 'round-down')
  imgSize.height = roundSize(imgSize.height, unit, 'round-down')

  const viewBox = `0 0 ${imgSize.width} ${imgSize.height}`
  const attr = { id: 'canvas', viewBox }
  if (destination === 'download' || destination === 'print') {
    attr.width = imgSize.width
    attr.height = imgSize.height
  }
  const canvas = createElementSVG('svg', attr)
  canvas.dataset.smallerSize = imgSize.width < imgSize.height ? imgSize.width : imgSize.height

  let bg = null
  if (destination !== 'preview' && destination !== 'template') {
    const defs = createElementSVG('defs')
    bg = createElementSVG('svg', { id: 'bg-image', viewBox })
    defs.appendChild(bg)
    canvas.appendChild(defs)
    canvas.appendChild(createElementSVG('use', { href: '#bg-image' }))
  }
  return { canvas, bg }
}

function fixCanvasForPrint(opt, svg, unit, destination) {
  const o = opt.document.image
  if (!o.bleedOrTrim) return svg

  const vboxWidth = roundSize(svg.viewBox.baseVal.width, unit, 'round-down')
  const vboxHeight = roundSize(svg.viewBox.baseVal.height, unit, 'round-down')
  const value = convertSize(o.bleedOrTrim, o.size.unit, unit)

  if (o.bleedOrTrim > 0) {
    // positive, it's a margin not a bleed
    const size = vboxWidth < vboxHeight ? vboxWidth : vboxHeight
    const k = ((size - 2 * value) * 100) / size
    const tr = { x: 0, y: 0, scale: k }
    const attr = { id: 'print', viewBox: `0 0 ${vboxWidth} ${vboxHeight}` }
    if (destination === 'print') {
      attr.width = vboxWidth
      attr.height = vboxHeight
    }
    const print = createElementSVG('svg', attr)

    const g = createElementSVG('g')
    svg.setAttribute('preserveAspectRatio', 'xMinYMin meet')
    g.appendChild(svg)
    print.appendChild(g)
    transformImage(g, tr, destination)
    svg = print // eslint-disable-line
  } else {
    // negative: it's a bleed
    // this case is taken care of during canvas construction
  }

  if (o.crop_marks) {
    const path = createElementSVG('path')
    path.setAttribute('fill', 'none')
    path.setAttribute('stroke', o.crop_marks_color)
    path.setAttribute('stroke-width', '1')

    const x = Math.abs(o.bleedOrTrim > 0 ? value * 3 : value)
    let d
    if (o.bleedOrTrim > 0) {
      // crop marks for margin
      d = `M0 ${x}v${-x}h${x}
      M0 ${vboxHeight - x}v${x}h${x}
      M${vboxWidth - x} 0h${x}v${x}
      M${vboxWidth - x} ${vboxHeight} h${x}v${-x}`
    } else {
      // crop marks for bleed
      d = `M0 ${x}h${x}v${-x}
           M0 ${vboxHeight - x}h${x}v${x}
           M${vboxWidth - x} 0v${x}h${x}
           M${vboxWidth - x} ${vboxHeight} v${-x}h${x}`
    }
    path.setAttribute('d', d)
    svg.appendChild(path)
  }
  return svg
}

function toggleHideElement(element, hide) {
  if (element.id === 'qr') {
    const svg = element.firstElementChild
    const bg = svg.querySelector('rect')
    const points = svg.querySelector('g#points')
    const finders = svg.querySelector('g#finders')

    if (hide && !svg.hasAttribute('stroke-width')) {
      const color = points.getAttribute('fill')
      svg.setAttribute('stroke-width', '0.05')
      bg.style.visibility = 'hidden'
      points.setAttribute('stroke', color)
      finders.setAttribute('stroke', color)
      svg.setAttribute('fill-opacity', 0)
    } else if (!hide && svg.hasAttribute('stroke-width')) {
      bg.style.visibility = 'visible'
      svg.removeAttribute('stroke-width')
      svg.removeAttribute('fill-opacity')
      points.removeAttribute('stroke')
      finders.removeAttribute('stroke')
    }
  } else if (hide) element.classList.add('hidden')
  else element.classList.remove('hidden')
}

/* eslint no-await-in-loop: "off" */
async function constructImage(data, opt, unit, destination) {
  const constructedCanvas = constructCanvas(opt, unit, destination)
  let { canvas } = constructedCanvas
  let elTarget = constructedCanvas.bg || canvas
  if (destination === 'preview') opt.selected = {}
  usedGoogleFonts = {}
  const invalidLayers = []
  for (let i = opt.document.content.length - 1; i >= 0; i -= 1) {
    const layer = opt.document.content[i]

    if (destination !== 'preview' && layer.hidden) continue // eslint-disable-line

    let element
    if (layer.qr) {
      element = generateQRImage(layer, data)
      elTarget = canvas
    } else if (layer.img) {
      element = await loadImage(layer, opt.userMedia)
    } else if (layer.txt) {
      element = await createText(layer, destination)
    } else if (layer.background) {
      element = createBackgroundPattern(layer, canvas.viewBox.baseVal)
    } else {
      /* other types coming soon */
    }

    if (element) {
      element.dataset.idx = layer.idx
      elTarget.appendChild(element)

      setColor(layer, element)
      if (layer.frame?.active) setItemFrame(layer, element)
      if (layer.transform && !layer.background) transformImage(element, layer.transform, destination)

      if (destination === 'preview') {
        if (layer.hidden) toggleHideElement(element, true)
        if (layer.selected) opt.selected.layer = layer
        else element.classList.add('selectable')

        if (layer.background) element.dataset.outlineW = Math.ceil(canvas.dataset.smallerSize / 400)

        element.addEventListener('click', e => {
          e.stopImmediatePropagation()
          selectElement(opt, e.currentTarget)
        })

        element.addEventListener('mouseenter', e => {
          e.stopImmediatePropagation()
          document.documentElement.style.setProperty('--qr-element-outline-width', `${element.dataset.outlineW}px`)
        })
      }
    } else invalidLayers.push(layer)
  } // end for loop

  invalidLayers.forEach(i => { opt.document.content = opt.document.content.filter(x => x !== i) })

  if (destination !== 'download') {
    canvas = fixCanvasForPrint(opt, canvas, unit, destination)
  }
  canvas.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
  if (constructedCanvas.bg && Object.keys(usedGoogleFonts).length) {
    constructedCanvas.bg.prepend(await embeddGoogleFonts(usedGoogleFonts))
  }
  return canvas
}

async function makeFileSVG(svg) {
  const data = new Blob([svg.outerHTML], { type: 'image/svg+xml;charset=utf-8' })
  return data
}

async function makeFilePNG(svg, zip = false, size = null) {
  const scale = 600 / 72
  const base64Str = data => {
    const idx = data.indexOf('base64,') + 'base64,'.length
    return data.substring(idx)
  }
  const w = size ? size.width : svg.viewBox.baseVal.width * scale
  const h = size ? size.height : svg.viewBox.baseVal.height * scale
  const canvas = document.createElement('canvas')
  canvas.width = w
  canvas.height = h
  const ctx = canvas.getContext('2d')

  const blob = new Blob([svg.outerHTML], { type: 'image/svg+xml;charset=utf-8' })
  const url = domUrl.createObjectURL(blob)

  const img = new Image()
  img.src = url
  await img.decode()

  ctx.drawImage(img, 0, 0, w, h)
  domUrl.revokeObjectURL(url)
  const dataUrl = canvas.toDataURL('image/png')
  return zip ? base64Str(dataUrl) : dataUrl
}

async function* generateDownload(data, opt) {
  const content = {
    prefix: data[0].prefix,
    naturalSize: { width: null, height: null, unit: 'px' },
    data: [],
  }

  let canvas; let defs; let frame; let qrBbox
  if (!opt.print.qrRemoveBG) {
    canvas = await constructImage(data[0], opt, 'px', 'download')
    frame = canvas.querySelector('#qr>svg#frame')
    if (frame) {
      const q = frame.querySelector('svg')
      qrBbox = {
        x: q.getAttribute('x'), y: q.getAttribute('y'), width: q.getAttribute('width'), height: q.getAttribute('height'),
      }
    }
    if (frame) frame.querySelector('svg').remove()
    else canvas.querySelector('#qr>svg').remove()

    defs = canvas.querySelector('defs')
    defs.remove()
    content.defs = defs
  }

  const qrOptions = (opt.print.qrRemoveBG && opt.print.qrRemoveStylizing)
    ? { qr: { tag: opt.document.content[0].qr.tag } } : opt.document.content[0]

  let img
  for (let i = 0; i < data.length; i += 1) {
    const qr = generateQRImage(qrOptions, data[i])
    if (qrBbox) {
      qr.firstChild.setAttribute('x', qrBbox.x)
      qr.firstChild.setAttribute('y', qrBbox.y)
      qr.firstChild.setAttribute('width', qrBbox.width)
      qr.firstChild.setAttribute('height', qrBbox.height)
    }
    setColor(qrOptions, qr)
    if (opt.print.qrRemoveBG) img = qr.firstChild
    else {
      img = canvas.cloneNode(true)
      if (i === 0) img.prepend(defs)
      const qrSvgParent = frame ? img.querySelector('#qr>svg#frame') : img.querySelector('#qr')
      qrSvgParent.appendChild(qr.firstChild)
    }
    img.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    img.dataset.prefix = data[i].prefix
    if (data[i].tag) img.dataset.tag = data[i].tag
    content.data.push(img)
    yield img
  }
  content.naturalSize.width = img.viewBox.baseVal.width
  content.naturalSize.height = img.viewBox.baseVal.height
  yield content
}

async function* generatePages(data, opt) {
  const unit = 'px'
  const pOpt = opt.print.page
  const pageSize = getBoxSize(pOpt.size, unit, 'round')
  const gap = convertSize(pOpt.img_gap, pOpt.size.unit, unit)
  const margin = getMarginSize(pOpt.margin, pOpt.size.unit, unit)
  const pageT = margin[0]
  const pageR = pageSize.width - margin[1]
  const pageB = pageSize.height - margin[2]
  const pageL = margin[3]

  const docDefinition = {
    pageSize: { width: pageSize.width, height: pageSize.height },
    pageOrientation: pageSize.width < pageSize.height ? 'portrait' : 'landscape',
    pageMargins: 0, // a number or [l/r, t/b] or [l, t, r, b]
    images: { background: null },
    info: { title: `${opt.zipFname || data[0].prefix} QR Codes` },
    content: [],
  }

  const pageAttr = {
    viewBox: `0 0 ${pageSize.width} ${pageSize.height}`,
    xmlns: 'http://www.w3.org/2000/svg',
  }

  const canvas = await constructImage(data[0], opt, unit, 'print')
  const frame = canvas.querySelector('#qr>svg#frame')
  let qrBbox
  if (frame) {
    const q = frame.querySelector('svg')
    qrBbox = {
      x: q.getAttribute('x'), y: q.getAttribute('y'), width: q.getAttribute('width'), height: q.getAttribute('height'),
    }
    frame.querySelector('svg').remove()
  } else canvas.querySelector('#qr>svg').remove()

  makeFilePNG(canvas).then(v => { docDefinition.images.background = v })

  const defs = canvas.querySelector('defs')
  defs.remove()

  const width = roundSize(canvas.viewBox.baseVal.width, unit, 'round-down')
  const height = roundSize(canvas.viewBox.baseVal.height, unit, 'round-down')
  const xStep = width + gap
  const yStep = height + gap

  let x = pageL
  let y = pageT
  let pageBreak = true
  let page = null

  for (let i = 0; i < data.length; i += 1) {
    const qr = generateQRImage(opt.document.content[0], data[i])
    if (qrBbox) {
      qr.firstChild.setAttribute('x', qrBbox.x)
      qr.firstChild.setAttribute('y', qrBbox.y)
      qr.firstChild.setAttribute('width', qrBbox.width)
      qr.firstChild.setAttribute('height', qrBbox.height)
    }
    setColor(opt.document.content[0], qr)

    for (let k = 0; k < opt.print.copies; k += 1) {
      const img = canvas.cloneNode(true)
      const qrSvgParent = frame ? img.querySelector('#qr>svg#frame') : img.querySelector('#qr')
      qrSvgParent.appendChild(qr.firstChild.cloneNode(true))
      const pdfQR = { svg: img.outerHTML, absolutePosition: { x, y } }
      const pdfBG = {
        image: 'background', absolutePosition: { x, y }, width, height,
      }
      if (i === 0 && k === 0) img.prepend(defs)
      img.setAttribute('x', x)
      img.setAttribute('y', y)
      if (pageBreak || !page) {
        if (page) pdfBG.pageBreak = 'before'
        page = createElementSVG('svg', pageAttr)
        page.classList.add('page')
        pageBreak = false
      }

      page.appendChild(img)
      docDefinition.content.push(pdfBG)
      docDefinition.content.push(pdfQR)

      x += xStep

      if (x + xStep > pageR) {
        x = pageL
        y += yStep
      }
      if (y + yStep > pageB) {
        y = pageT
        pageBreak = true
        yield page
      }
    }
  }
  if (!pageBreak) yield page
  yield docDefinition
}

async function downloadQRcode(content, options) {
  const ext = options.print.fileFormat.toLowerCase()
  const makeFileF = options.print.fileFormat === 'SVG' ? makeFileSVG : makeFilePNG
  if (content.data?.length > 1) {
    const zip = new JSZip()
    await Promise.all(content.data.map(async svg => {
      if (content.defs) svg.prepend(content.defs)
      const fdata = await makeFileF(svg, /* zip */ true, content.size)
      const fname = `${svg.dataset.prefix + (svg.dataset.tag ? ` - ${svg.dataset.tag}` : '')} - QR.${ext}`
      zip.file(fname, fdata, { base64: true })
    }))

    const zipGenerated = await zip.generateAsync({ type: 'blob' })
    saveAs(zipGenerated, `${options.zipFname || content.prefix} - QrCodes.zip`)
  } else {
    const svg = content.data[0]
    if (content.defs) svg.prepend(content.defs)
    const fdata = await makeFileF(svg, false, content.size)
    const fname = `${svg.dataset.prefix + (svg.dataset.tag ? ` - ${svg.dataset.tag}` : '')} - QR.${ext}`
    saveAs(fdata, fname)
  }
}

async function makeFile(content, options) {
  if (options.print.fileFormat === 'PDF') pdfMake.createPdf(content).open()
  else downloadQRcode(content, options)
}

export {
  setColor,
  constructImage,
  generatePages,
  generateDownload,
  selectElement,
  unselectElement,
  toggleHideElement,
  makeFile,
  downloadQRcode,
  displayGridLines,
  setItemFrame,
}
