<template>
  <div class="ml-1">
    <b-button
      :id="String(_uid)"
      href="#"
      variant="outline-primary"
      class="btn-icon d-flex"
      :size="isBtnSizeSm ? 'sm' : null"
      :disabled="disabled"
      @click="add"
    >
      <feather-icon icon="ImageIcon" />
    </b-button>
    <b-tooltip
      :target="String(_uid)"
      :triggers="isSubscriptionInactiveOrBasic || isTooltip ? 'focus' : 'hover'"
    >
      <div
        v-if="isSubscriptionInactiveOrBasic || isTooltip"
        class="pt-50 pb-50"
      >
        <span>
          {{ isSubscriptionInactiveOrBasic ? $t('tooltipPhotosSubscribe') : $t('tooltipPhotosUpgrade') }}
        </span>
        <b-button
          block
          size="sm"
          class="mt-50"
          variant="primary"
          @click="$router.push({ name: 'billing' })"
        >
          {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
        </b-button>
      </div>
      <template v-else>
        {{ $t('Add Image') }}
      </template>
    </b-tooltip>
    <image-upload
      v-model="isImageUpload"
      :size="{ width: 800, height: 400 }"
      @posting="$emit('posting')"
      @done="$emit('done', $event)"
    />
  </div>
</template>

<script>
import {
  BButton,
  BTooltip,
} from 'bootstrap-vue'
import ImageUpload from '@/components/ImageUpload.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AddToContentImage',
  components: {
    BButton,
    BTooltip,
    ImageUpload,
  },
  props: {
    isBtnSizeSm: {
      type: Boolean,
      required: true,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false,
    },
    contentImageLength: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isImageUpload: false,
    }
  },
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      isSubscriptionActive: 'billing/isSubscriptionActive',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionCanceled: 'billing/isSubscriptionCanceled',
    }),
    isTooltip() {
      return this.subscription
        && (this.isSubscriptionActive || this.isSubscriptionCanceled)
        && this.subscription?.productDetails.name === 'Standard'
        && this.isMedialLimitReached
    },
    isMedialLimitReached() {
      return this.subscription?.productDetails.restrictions.menuItemMediaAmount <= this.contentImageLength
    },
  },
  methods: {
    add() {
      if (this.isSubscriptionInactive || this.isMedialLimitReached) {
        return
      }

      this.isImageUpload = true
    },
  },
}
</script>
