<template>
  <b-modal
    id="modal-confirm-delete"
    :visible="value"
    centered
    hide-header
    hide-footer
    @hide="$emit('input', false)"
  >
    <div class="d-flex flex-column align-items-center text-center">
      <feather-icon
        icon="InfoIcon"
        size="80"
        class="d-flex mb-2"
      />
      <h3 class="mb-0">
        {{ title || $t('modalConfirmDeleteTitle') }}
      </h3>
      <b-card-text
        v-if="isMessage"
        class="mt-1 mb-0"
      >
        {{ message || $t('You won’t be able to revert this!') }}
      </b-card-text>
      <div class="mt-2 pt-1">
        <b-button
          variant="primary"
          class="mr-1"
          :disabled="isDeleting"
          @click="confirm"
        >
          <template v-if="isDeleting">
            <b-spinner
              small
              class="d-flex"
            />
          </template>
          <template v-else>
            {{ $t('Delete') }}
          </template>
        </b-button>
        <b-button
          variant="outline-primary"
          :disabled="isDeleting"
          @click="$emit('input', false)"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BCardText,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

export default {
  name: 'ModalConfirmDelete',
  components: {
    BModal,
    BCardText,
    BButton,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    isMessage: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      isDeleting: false,
    }
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.isDeleting = false
      }
    },
  },
  methods: {
    confirm() {
      this.isDeleting = true
      this.$emit('delete')
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  #modal-confirm-delete
    .modal-body
      padding-top: 3rem
      padding-bottom: 3rem
    .feather-info
      color: $orange
      transform: rotate(180deg)
</style>
