<template>
  <span>
    <template v-if="isCurrencySymbolBefore">{{ currencySymbol }}</template>
    <slot />
    <template v-if="isCurrencySymbolAfter">{{ currencySymbol }}</template>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CurrencySymbol',
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      currencies: 'dictionaries/currencies',
    }),
    currencySymbol() {
      if (this.restaurant && this.currencies.length) {
        const currency = this.currencies.find(i => i.code === this.restaurant.primaryCurrency)
        return currency ? currency.symbolNative : ''
      }

      return ''
    },
    isCurrencySymbolBefore() {
      return (
        this.currencySymbol
        && this.restaurant.showCurrencySymbol
        && this.restaurant.showCurrencySymbolInFront
      )
    },
    isCurrencySymbolAfter() {
      return (
        this.currencySymbol
        && this.restaurant.showCurrencySymbol
        && !this.restaurant.showCurrencySymbolInFront
      )
    },
  },
}
</script>
