<template>
  <div class="card-inner">
    <b-card class="mb-0">
      <b-form-group
        :label="$t('Category Name')"
        class="mb-0"
      >
        <b-input-group class="input-group-merge">
          <b-form-input
            :value="translationName"
            :disabled="isSubscriptionInactiveOrBasic"
            :placeholder="$t('Category Name')"
            @input="onInputName"
            @blur="onBlurName"
          />
          <b-input-group-append
            v-if="isSubscriptionPremium"
            is-text
          >
            <icon-translate
              v-b-tooltip.hover.top="$t('Auto Translate')"
              class="size-18"
              role="button"
              @click.native="onClickTranslateField('name')"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div
        v-if="translationContentText.length"
        class="mt-2 pt-50"
      >
        <b-form-group
          :label="$t('Category Contents')"
          class="mb-0"
        >
          <b-input-group
            v-for="(contentItem, contentItemIndex) in translationContentText"
            :key="contentItem.id"
            class="input-group-merge"
            :class="[
              'input-group-merge',
              { 'mb-1': contentItemIndex + 1 !== translationContentText.length },
            ]"
          >
            <b-form-textarea
              :value="contentItem.value"
              rows="8"
              no-resize
              :disabled="isSubscriptionInactiveOrBasic"
              :placeholder="$t('Text')"
              @input="onInputText($event, contentItem.id)"
              @blur="onBlurText"
            />
            <b-input-group-append
              v-if="isSubscriptionPremium"
              is-text
            >
              <icon-translate
                v-b-tooltip.hover.top="$t('Auto Translate')"
                class="size-18"
                role="button"
                @click.native="onClickTranslateContent(contentItem.id)"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import IconTranslate from '@/components/icon/IconTranslate.vue'
import mixinGoogleTranslation from '@/mixins/google-translation'
import mixinRequests from '@/mixins/requests'
import mixinGqlCategory from '@/mixins/gql/category'

export default {
  name: 'CategoryTranslation',
  components: {
    BCard,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    IconTranslate,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    mixinGoogleTranslation,
    mixinRequests,
    mixinGqlCategory,
  ],
  props: {
    categoryData: {
      type: Object,
      required: true,
      default: () => {},
    },
    translations: {
      type: Array,
      required: true,
      default: () => [],
    },
    translationActive: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      isSubscriptionInactiveOrBasic: 'billing/isSubscriptionInactiveOrBasic',
      isSubscriptionPremium: 'billing/isSubscriptionPremium',
    }),
    translationName() {
      return this.translations.find(i => i.language === this.translationActive).name
    },
    translationContentText() {
      return this.translations.find(i => i.language === this.translationActive).content.filter(i => i.type === 'Text')
    },
  },
  methods: {
    patchTranslationActive() {
      return [
        this.updatedCategoryTranslation(
          this.categoryData.id,
          this.translations.find(i => i.language === this.translationActive),
        ),
      ]
    },

    updateName(newValue) {
      const toEmit = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            name: newValue,
          }
        }
        return i
      })
      this.$emit('updateTranslations', toEmit)
    },
    updateContentItem(newValue, contentItemId) {
      const toEmit = this.translations.map(i => {
        if (i.language === this.translationActive) {
          return {
            ...i,
            content: i.content.map(j => (j.id === contentItemId ? { ...j, value: newValue } : j)),
          }
        }
        return i
      })
      this.$emit('updateTranslations', toEmit)
    },

    onInputName(newValue) {
      this.updateName(newValue)
    },
    onInputText(newValue, contentItemId) {
      this.updateContentItem(newValue, contentItemId)
    },

    onBlurName() {
      this.requests.push('patchTranslationActive')
    },
    onBlurText() {
      this.requests.push('patchTranslationActive')
    },

    async onClickTranslateField(path) {
      const response = await this.getGoogleTranslation([
        this.categoryData[path],
      ])
      this.updateName(response[0])

      this.requests.push('patchTranslationActive')
    },
    async onClickTranslateContent(contentItemId) {
      const response = await this.getGoogleTranslation([
        this.categoryData.content.find(i => i.id === contentItemId).value,
      ])
      this.updateContentItem(response[0], contentItemId)

      this.requests.push('patchTranslationActive')
    },
  },
}
</script>
