import { mapGetters } from 'vuex'
import { get as lodashGet, set as lodashSet } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ToastificationContent,
  },
  computed: {
    ...mapGetters({
      languages: 'dictionaries/languages',
      restaurant: 'restaurant/restaurant',
    }),
  },
  methods: {
    async translateField(path) {
      const value = lodashGet(this, path)
      if (!value) {
        return
      }

      const response = await this.getGoogleTranslation([value])
      if (response) {
        const [responseValue] = response

        const translation = this.translations.find(i => i.language === this.translationActive)
        lodashSet(translation, path, responseValue)
      }
    },
    async translateContent(id) {
      const val = this.content.find(i => i.id === id).value
      if (!val) {
        return
      }

      const response = await this.getGoogleTranslation([val])
      if (response) {
        const [responseValue] = response

        const translation = this.translations.find(i => i.language === this.translationActive)
        translation.content.find(i => i.id === id).value = responseValue
      }
    },
    async getGoogleTranslation(qArray, primaryLanguage) {
      try {
        const shortNameOfLanguageSource = primaryLanguage || this.restaurant?.primaryLanguage
        const languageSource = this.languages.find(i => i.shortName === shortNameOfLanguageSource)
        if (!languageSource) {
          return null
        }

        const languageTarget = this.languages.find(i => i.shortName === this.translationActive)
        if (!languageTarget) {
          return null
        }
        if (!languageTarget.iso6391) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error'),
              text: this.$t('notificationErrorNoAutoTranslation'),
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
          return null
        }

        const response = await fetch(
          `https://translation.googleapis.com/language/translate/v2?key=${process.env.VUE_APP_GOOGLE_KEY}&format=text`,
          {
            method: 'POST',
            body: JSON.stringify({
              q: qArray,
              source: languageSource.iso6391,
              target: languageTarget.iso6391,
            }),
          },
        )
        const { data } = await response.json()

        return data.translations.map(i => i.translatedText)
      } catch (error) {
        return error
      }
    },
  },
}
