<template>
  <b-row class="menu-content-image">
    <b-col>
      <div class="d-flex align-items-center">
        <icon-move-vertical class="card-content__transparent mr-1" />
        <div class="lightgallery">
          <a
            class="menu-content-image__link"
            :href="`${VUE_APP_IMG_BASE}/${image.value}`"
          >
            <div
              class="menu-content-image__thumbnail"
              :style="{ backgroundImage: `url(${VUE_APP_IMG_BASE}/${image.value})` }"
            />
          </a>
        </div>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
    >
      <feather-icon
        :class="['card-content__transparent', { 'feather-disabled': !isContentValid }]"
        role="button"
        size="20"
        icon="TrashIcon"
        @click="$emit('delete')"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import IconMoveVertical from '@/components/icon/IconMoveVertical.vue'

export default {
  name: 'MenuContentImage',
  components: {
    BRow,
    BCol,
    IconMoveVertical,
  },
  props: {
    image: {
      type: Object,
      required: true,
      default: () => ({ id: '', type: 'Image', value: '' }),
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      VUE_APP_IMG_BASE: 'main/VUE_APP_IMG_BASE',
    }),
  },
}
</script>

<style lang="sass">
@import '@core/scss/base/bootstrap-extended/_variables.scss'

.menu-content-image
  &__link
    position: relative
    display: block
    &::after
      content: ""
      display: block
      position: absolute
      top: 0
      left: 0
      right: 0
      bottom: 0
      background-color: rgba($primary, 0.5)
      opacity: 0
      transition: opacity 0.3s ease-in-out
      border-radius: $border-radius
    &:hover
      &::after
        opacity: 1
  &__thumbnail
    flex-shrink: 0
    width: 200px
    height: 100px
    border-radius: $border-radius
    background-size: cover
    background-repeat: no-repeat
    background-position: center
</style>
