<template>
  <b-modal
    v-model="isModal"
    :value="value"
    centered
    hide-footer
    :title="title || $t('Scan the QR code to see the menu on your phone')"
  >
    <div class="d-flex flex-column align-items-center text-center pb-2">
      <div
        ref="qr-container"
        class="mt-1 mb-2"
      />
      <b-button
        variant="outline-primary"
        @click="preview"
      >
        {{ $t('Open in browser') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import { generateQRImage } from '@/qr/qrcodeGen'
import { setColor } from '@/qr/qrcode'

export default {
  name: 'ModalPreview',
  components: {
    BModal,
    BButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    link: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      previewQr: null,
    }
  },
  computed: {
    isModal: {
      get() {
        return this.value
      },
      set() {
        this.$emit('input', !this.value)
      },
    },
  },
  watch: {
    value(newValue) {
      if (newValue) {
        const qrOpt = {
          qr: {},
          color: {
            fill: '#ffffff',
            stroke: '#000000',
          },
        }
        const qr = generateQRImage(qrOpt, { content: this.link })
        setColor(qrOpt, qr)

        this.previewQr = qr.firstChild
        this.previewQr.setAttribute('height', 300)
        this.previewQr.setAttribute('width', 300)

        this.$nextTick(() => {
          this.$refs['qr-container'].appendChild(this.previewQr)
        })
      }
    },
  },
  methods: {
    preview() {
      window.open(this.link, '_blank')
    },
  },
}
</script>
