export default {
  data() {
    return {
      updateTranslationMutation: null,
      createTranslationMutation: null,
      createTranslationResponseObjectName: null,
    }
  },
  methods: {
    patchTranslation(translation) {
      return [
        this.$apollo.mutate({
          mutation: this.updateTranslationMutation,
          variables: {
            originalId: this.$route.params.id,
            restaurant: this.restaurant.id,
            ...this.getTranslationPayload(translation),
          },
        }),
      ]
    },
    patchTranslations() {
      return this.translations.flatMap(translation => this.patchTranslation(translation))
    },
    patchTranslationActive() {
      const translation = this.translations.find(i => i.language === this.translationActive)

      if (translation) {
        const patchTranslation = this.patchTranslation.bind(this, translation)
        this.requests.push(patchTranslation)
      }
    },

    async postTranslations(languages) {
      const responses = await Promise.all(languages.map(language => this.$apollo.mutate({
        mutation: this.createTranslationMutation,
        variables: {
          originalId: this.$route.params.id,
          restaurant: this.restaurant.id,
          ...this.getTranslationPayloadClear(language),
        },
      })))

      this.translations = [
        ...this.translations,
        ...responses.map(i => i.data[this.createTranslationResponseObjectName]),
      ]

      // eslint-disable-next-line no-undef
      this.translations = structuredClone(this.translations)
        .sort((a, b) => a.language.localeCompare(b.language))
    },
    async setTranslations(responseTranslations) {
      // eslint-disable-next-line no-undef
      this.translations = structuredClone(responseTranslations).sort((a, b) => a.language.localeCompare(b.language))

      const translationsToPost = this.restaurant.translationLanguages
        .filter(i => !this.translations.find(j => j.language === i))
      if (translationsToPost.length) {
        await this.postTranslations(translationsToPost)
      }

      const translationActive = this.translations.find(i => i.isEnabled)
      this.translationActive = translationActive ? translationActive.language : ''
    },
  },
}
