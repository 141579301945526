<template>
  <my-upload
    ref="myUpload"

    no-circle
    :max-size="5000"
    :width="size.width"
    :height="size.height"
    :lang-type="$i18n.locale"

    :value="value"
    @input="$emit('input', $event)"

    @src-file-set="onSrcFileSet"
    @crop-success="onCropSuccess"
  />
</template>

<script>
import myUpload from 'vue-image-crop-upload/upload-2.vue'
import imageCompression from 'browser-image-compression'
import UPLOAD_IMAGE from '@/gql/mutation/uploadImage.gql'

export default {
  name: 'ImageUpload',
  components: {
    myUpload,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
      default: false,
    },
    size: {
      type: Object,
      required: true,
      default: () => ({ width: 800, height: 800 }),
    },
  },
  data() {
    return {
      myUploadFileName: '',
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        if (this.$refs.myUpload.step === 1) {
          this.$refs.myUpload.reset()
          this.$refs.myUpload.$refs.fileinput.value = null
        }
      }
    },
  },
  methods: {
    onSrcFileSet(fileName) {
      this.myUploadFileName = fileName
    },
    async getCompressedFile(base64) {
      const fileName = this.myUploadFileName ? this.myUploadFileName : `${Date.now()}`
      this.myUploadFileName = ''

      const file = await imageCompression.getFilefromDataUrl(base64, fileName)
      const blob = await imageCompression(file, {
        alwaysKeepResolution: true,
        maxSizeMB: 0.2,
      })

      return new File([blob], fileName)
    },
    async onCropSuccess(base64) {
      this.$emit('input', false)

      this.$emit('posting')

      const response = await this.$apollo.mutate({
        mutation: UPLOAD_IMAGE,
        variables: {
          imageFile: await this.getCompressedFile(base64),
        },
      })

      this.$emit('done', response.data.uploadImage)

      this.$refs.myUpload.setStep(1)
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/base/bootstrap-extended/_variables.scss'

  .vue-image-crop-upload .vicp-wrap
    .vicp-step2 .vicp-crop
      .vicp-crop-right .vicp-preview .vicp-preview-item
        &::after
          content: '🧐'
          display: block
          position: absolute
          bottom: -30px
          left: 50%
          font-size: 20px
          transform: translateX(-50%)
        img
          border-radius: $border-radius
        span
          display: none
      .vicp-crop-left .vicp-range input[type=range]
        padding-top: 9px
        &::-webkit-slider-thumb
          background-color: $primary
        &::-moz-range-thumb
          background-color: $primary
        &::-ms-thumb
          background-color: $primary
        &::-webkit-slider-runnable-track
          background-color: rgba($primary, 0.3)
        &::-moz-range-track
          background-color: rgba($primary, 0.3)
        &::-ms-track
          background-color: rgba($primary, 0.3)
        &::-ms-fill-lower
          background-color: rgba($primary, 0.3)
        &::-ms-fill-upper
          background-color: rgba($primary, 0.15)
        &:focus
          &::-webkit-slider-runnable-track
            background-color: rgba($primary, 0.5)
          &::-moz-range-track
            background-color: rgba($primary, 0.5)
          &::-ms-fill-lower
            background-color: rgba($primary, 0.45)
          &::-ms-fill-upper
            background-color: rgba($primary, 0.25)
    .vicp-step3 .vicp-upload .vicp-progress-wrap .vicp-progress
      background-color: $primary
      box-shadow: 0 2px 6px 0 rgba($primary, 0.3)
      &::after
        background-color: $primary
        box-shadow: 0 1px 4px 0 rgba($primary, 0.7)
    .vicp-operate a
      color: $primary
</style>
