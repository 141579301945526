import { mapGetters } from 'vuex'

import CREATE_CATEGORY from '@/gql/mutation/category/createCategory.gql'
import UPDATE_CATEGORY from '@/gql/mutation/category/updateCategory.gql'
import DELETE_CATEGORY from '@/gql/mutation/category/deleteCategory.gql'

import GET_CATEGORY_TRANSLATIONS from '@/gql/query/menu-managment/getCategoryTranslations.gql'
import CREATE_CATEGORY_TRANSLATION from '@/gql/mutation/category/createCategoryTranslation.gql'
import UPDATE_CATEGORY_TRANSLATION from '@/gql/mutation/category/updateCategoryTranslation.gql'

export default {
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
    }),
  },
  methods: {
    createCategory(categoryName) {
      return [
        this.$apollo.mutate({
          mutation: CREATE_CATEGORY,
          variables: {
            restaurant: this.restaurant.id,
            name: categoryName,
          },
        }),
      ]
    },
    updateCategory(id, variables = {}) {
      return [
        this.$apollo.mutate({
          mutation: UPDATE_CATEGORY,
          variables: {
            updateCategoryId: id,
            ...variables,
          },
        }),
      ]
    },
    deleteCategory(id) {
      return [
        this.$apollo.mutate({
          mutation: DELETE_CATEGORY,
          variables: {
            deleteCategoryId: id,
          },
        }),
      ]
    },

    getCategoryTranslations(categoryId) {
      return this.$apollo.query({
        query: GET_CATEGORY_TRANSLATIONS,
        variables: {
          getCategoryTranslationsId: categoryId,
        },
      })
    },
    createCategoryTranslation(categoryId, language, name, content) {
      return this.$apollo.mutate({
        mutation: CREATE_CATEGORY_TRANSLATION,
        variables: {
          originalId: categoryId,
          restaurant: this.restaurant.id,
          language,
          name,
          content,
        },
      })
    },
    updatedCategoryTranslation(categoryId, translation) {
      return this.$apollo.mutate({
        mutation: UPDATE_CATEGORY_TRANSLATION,
        variables: {
          originalId: categoryId,
          restaurant: this.restaurant.id,
          language: translation.language,
          name: translation.name || null,
          content: translation.content.map(i => ({ id: i.id, type: i.type, value: i.value || null })),
        },
      })
    },
  },
}
