/* eslint-disable */

import qrcodegen from "nayuki-qr-code-generator";
import { createElementSVG } from "@/qr/utils";
const errCorLvl = qrcodegen.QrCode.Ecc.LOW; // Error correction level

const randomInt = (max) => Math.floor(Math.random() * max);

// multiple segments in either horizontal or vertical direction
// Each segment starts where the prvious ends
// arguments:
//  startX, startY -- coordinates of the starting point
// step_size -- length of each step before the jitter applied
// jitter_major -- jitter along the segment direction
// jitter_minor -- jitter orthogonal to segment direction
// segments : [ [dir, length] ] -- array of [dirs, lenghths] (can be negative)
//    segments in either ("h") horizontal, or ("v") vertical direction
// z :bool -- (false)== polyline, (true)=polygon, ignored if only one segment

function zLine(direction, length, step_size, jitter_major, jitter_minor) {
  const random = (v) => parseInt(Math.random() * 200 - 100) * v / 100

  let d = "";

  const step = length > 0 ? step_size : -step_size;
  const steps = Math.round(length / step);

  let maj = 0,
    min = 0;

  let tmaj = 0,
    tmin = 0;
  for (let i = 1; i < steps; i++) {
    maj = random(jitter_major) - tmaj;
    min = random(jitter_minor) - tmin;
    tmaj += maj;
    tmin += min;
    const strMaj = (step + maj).toFixed(3)
    const strMin = (min).toFixed(3)
    d += direction == "h" ? ` l${strMaj},${strMin}` : ` l${strMin},${strMaj}`;
  }
  d +=
    direction == "h"
      ? ` l${step - tmaj},${-tmin}`
      : ` l${-tmin},${step - tmaj}`;
  tmaj -= maj;
  tmin -= min;
  return d;
}

function zPath(lines, step_size, jitter_major, jitter_minor) {
  return lines
    .map(([d, v]) => zLine(d, v, step_size, jitter_major, jitter_minor))
    .join("");
}

const path_styles = {
  default: (paths, margin) => {
    const d = paths.map( path => {
      const [Y, X] = path.shift()
      return `M${X+margin} ${Y+margin}` + path.map(p => p.join('')).join('')+'z'
    }).join('')
    return createElementSVG("path", { d, "fill-rule": "nonzero"})
  },
  ripple: (paths, margin) => {
    const d = paths.map( path => {
      const [Y, X] = path.shift()
      return `M${X+margin} ${Y+margin}` + zPath(path, 0.5, 0.1, 0.1) +'z'
    }).join('')
    return createElementSVG("path", { d, "fill-rule": "nonzero"})
  },
}

const point_styles = {
  pixel: (y, x) => {
    return createElementSVG("rect", {
      width: 0.96,
      height: 0.96,
      rx: 0.15,
      ry: 0.15,
      y: y + 0.02,
      x: x + 0.02,
    });
  },

  drunk: (y, x) => {
    const p = createElementSVG("rect", {
      width: 0.9,
      height: 0.9,
      transform: "rotate(" + (randomInt(40) - 20) + " 0.5 0.5)",
    });
    const svg = createElementSVG("svg", { y: y + 0.05, x: x + 0.05 });
    svg.appendChild(p);
    return svg;
  },
  diamond: (y, x) => {
    const p = createElementSVG("polygon", {
      points: "0.5,0 1 0.5, 0.5,1 0 0.5",
    });
    const svg = createElementSVG("svg", { y, x });
    svg.appendChild(p);
    return svg;
  },

  circle: (y, x) => {
    const p = createElementSVG("circle", { cx: 0.5, cy: 0.5, r: 0.5 });
    const svg = createElementSVG("svg", { y, x });
    svg.appendChild(p);
    return svg;
  },

  dot: (y, x) => {
    const p = createElementSVG("circle", { cx: 0.5, cy: 0.25, r: 0.3 });
    const svg = createElementSVG("svg", { y, x });
    svg.appendChild(p);
    return svg;
  },
};

function compositeFrame(type) {
  const g = createElementSVG("g");
  for (let i = 0; i < 7; i++) {
    g.appendChild(point_styles[type](0, i));
    g.appendChild(point_styles[type](6, i));
  }
  for (let i = 1; i < 6; i++) {
    g.appendChild(point_styles[type](i, 0));
    g.appendChild(point_styles[type](i, 6));
  }
  return g;
}

const finder_frame_styles = {
  default: () => {
    return createElementSVG("path", {
      d: "M0 0 h7v7h-7v-7 M1 1 v5h5v-5h-5",
      "fill-rule": "nonzero",
    });
  },

  ripple: () => {
    const outter = [
      ["h", 7],
      ["v", 7],
      ["h", -7],
      ["v", -7],
    ];
    const inner = [
      ["v", 5],
      ["h", 5],
      ["v", -5],
      ["h", -5],
    ];
    return createElementSVG("path", {
      "fill-rule": "nonzero",
      d:
        "M0 0 " +
        zPath(outter, 0.5, 0.1, 0.1) +
        "z M1 1 " +
        zPath(inner, 0.5, 0.1, 0.1) +
        "z",
    });
  },

  pixels: () => compositeFrame("pixel"),
  drunk: () => compositeFrame("drunk"),
  circles: () => compositeFrame("circle"),

  ring: () => {
    return createElementSVG("path", {
      d: `M0 3.5
       A 3.5,3.5 0,1,0 7,3.5
       A 3.5,3.5 0,1,0 0,3.5
       M 1 3.5
       A 2.5,2.5 0,1,1 6,3.5
       A 2.5,2.5 0,1,1 1,3.5`,
      "fill-rule": "nonzero",
    });
  },
  "square-ring": () => {
    return createElementSVG("path", {
      d: `M0 0 V7H7V0H0
       M 1 3.5
       A 2.5,2.5 0,1,1 6,3.5
       A 2.5,2.5 0,1,1 1,3.5`,
      "fill-rule": "nonzero",
    });
  },
};

function compositeEye(type) {
  const g = createElementSVG("g");
  for (let y = 0; y < 3; y++)
    for (let x = 0; x < 3; x++) g.appendChild(point_styles[type](y, x));
  return g;
}

const finder_eye_styles = {
  default: () => {
    return createElementSVG("rect", { width: 3, height: 3 });
  },

  pixels: () => compositeEye("pixel"),
  drunk: () => compositeEye("drunk"),

  ripple: () => {
    const params = [0.5, 0.1, 0.1];
    const outter = [
      ["h", 3],
      ["v", 3],
      ["h", -3],
      ["v", -3],
    ];
    return createElementSVG("path", {
      d: "M0 0 " + zPath(outter, ...params) + "z",
    });
  },
  "rounded-square": () => {
    return createElementSVG("rect", { width: 3, height: 3, rx: 1, ry: 1 });
  },

  circle: () => {
    return createElementSVG("circle", { cx: 1.5, cy: 1.5, r: 1.5 });
  },
  circles: () => {
    return compositeEye("circle");
  },
  "circles-solid": () => {
    const circles = compositeEye("circle");
    circles.appendChild(createElementSVG("path", { d: "M0.5,0.5h2v2h-2z" }));
    return circles;
  },
};

const effects = {
  splash: (key) => {
    const filter = createElementSVG("filter", { id: "filter" + key });
    filter.appendChild(
      createElementSVG("feTurbulence", {
        baseFrequency: "0.1",
        numOctaves: "2",
        seed: "0.1",
        stitchTiles: "stitch",
        type: "fractalNoise",
        result: "blob_turbulence",
      })
    );
    filter.appendChild(
      createElementSVG("feDisplacementMap", {
        scale: "3",
        in: "SourceGraphic",
        in2: "blob_turbulence",
      })
    );
    return filter;
  },
};

// <defs> contains all definitions for the <use> element
function getElementDefs(styles, style, id, defs) {
  style ||= "default";
  defs ||= createElementSVG("defs");
  const point = styles[style]();
  point.setAttribute("id", id);
  defs.appendChild(point);
  return defs;
}

function getEffect(effect, defs) {
  defs ||= createElementSVG("defs");
  const filter = effects[effect](effect);
  defs.appendChild(filter);
  return defs;
}

// remove the finders data from the qr
function removeQRfinders(qr_modules, size) {
  // positions of three conrners
  const positions = [
    [0, 0],
    [0, size - 7],
    [size - 7, 0],
  ];

  for (let [r, c] of positions)
    for (let i = r; i < r + 7; i++)
      for (let j = c; j < c + 7; j++) qr_modules[i][j] = false;
}

function getFinders(size, margin, frame_style, eye_style) {
  // positions of three conrners
  const positions = [
    [margin, margin],
    [margin, size - 7 + margin],
    [size - 7 + margin, margin],
  ];
  const g = createElementSVG("g", { id: "finders" });

  for (let [r, c] of positions) {
    const svg = createElementSVG("g", { transform: `translate(${c} ${r})` });
    const frame = (finder_frame_styles[frame_style] || finder_frame_styles['default'])();
    const eye = (finder_eye_styles[eye_style] || finder_eye_styles['default'])();
    eye.setAttribute("transform", "translate(2 2)");

    svg.appendChild(frame);
    svg.appendChild(eye);
    g.appendChild(svg);
  }

  return g;
}

function stylePoints(modules, margin, style) {
  const g = createElementSVG("g", { id: "points" });
  for (let y = 0; y < modules.length; y++)
    for (let x = 0; x < modules[y].length; x++) {
      if (modules[y][x]) {
        const p = point_styles[style](y + margin, x + margin);
        g.appendChild(p);
      }
    }
  return g;
}

function getPaths(m) {
  const [BLACK, WHITE] = [true, false]
  const [LEFT, RIGHT] = [true, false]
  const [EAST, SOUTH, WEST, NORTH ] = [0, 1, 2, 3]

  const path = []
  const size = m.length
  let direction = EAST // EAST=0, SOUTH=1, WEST=2, NORTH=3
  let Y, X, y, x, lastY, lastX

  // [y, x]
  const left = [[-1,0], [0,1], [1,0], [0,-1]]
  const rght = [[1,0], [0,-1], [-1,0], [0,1]]
  const next = [[0,1], [1,0], [0,-1], [-1,0]]

  const has = (dir, isBlack) => {
    const d = dir[direction]
    const [yy, xx] = [y+d[0], x+d[1]]
    return yy>=0 && yy<size && xx>=0 && xx<size && m[yy][xx] === isBlack
  }
  const turnLeft  = () => direction = (direction - 1) & 3
  const turnRight = () => direction = (direction + 1) & 3
  const stepForward = () => [y,x] = [y+next[direction][0],x+next[direction][1]]

  const startBlack = (y,x) => m[y][x] === true && (y===0 || !m[y-1][x]) && (x===0 || !m[y][x-1])
  const startWhite = (y,x) => m[y][x] === false && y>0 && x>0 && m[y-1][x-1] && m[y-1][x] && m[y][x-1]

  const pinBlackOffsetLeft  = [[0,0], [0,1], [1,1], [1,0]]
  const pinBlackOffsetRight = [[0,1], [1,1], [1,0], [0,0]]

  const pinWhiteOffsetRight = [[0,1], [0,0], [0,1], [1,1]]
  const pinWhiteOffsetLeft  = [[1,1], [1,0], [0,0], [0,1]]
  const pinAt = (isBlack, isLeft) => {
    const [dy, dx] = ( isBlack
      ? (isLeft ? pinBlackOffsetLeft : pinBlackOffsetRight)
      : (isLeft ? pinWhiteOffsetLeft : pinWhiteOffsetRight)
    )[direction]
    const pinY = y + dy
    const pinX = x + dx
    const link = pinY === lastY ? ['h', pinX - lastX] : ['v', pinY - lastY]
    lastY = pinY
    lastX = pinX
    return link
  }
  for (Y = 0; Y < size; Y += 1) {
    for (X = 0; X < size; X += 1) {
      if (startBlack(Y,X)) {
        direction = EAST
        lastY = y = Y
        lastX = x = X
        const segment = [ [Y, X] ]
        const falseStarts = [ ]
        while ( ! (direction === NORTH && y === Y && x === X) ) {
          if (startBlack(y,x) && direction === EAST) falseStarts.push([y,x])
          if (has(left, BLACK)) {
            segment.push(pinAt(BLACK, LEFT))
            if (direction === WEST) m[y+1][x+1] = 0 // invalidate visited (walked by) white start
            turnLeft()
            stepForward()
          } else if (has(next, BLACK)) {
            stepForward()
          } else {
            segment.push(pinAt(BLACK, RIGHT))
            turnRight()
          }
        }
        falseStarts.forEach(([y,x]) => m[y][x] = 1)
        path.push(segment)
      }
      else if (startWhite(Y,X)) {
        direction = SOUTH
        lastY = y = Y
        lastX = x = X
        const segment = [ [Y, X] ]
        const falseStarts = [ ]
        while ( ! (direction === WEST && y === Y && x === X) ) {
          if (startWhite(y,x) && direction === SOUTH) falseStarts.push([y,x])
          if (has(rght, WHITE)) {
            segment.push(pinAt(WHITE, RIGHT))
            turnRight()
            stepForward()
          } else if (has(next, WHITE)) {
            stepForward()
          } else {
            segment.push(pinAt(WHITE, LEFT))
            turnLeft()
          }
        }
        falseStarts.forEach(([y,x]) => m[y][x] = 0)
        path.push(segment)
      }
    }
  }
  return path
}

function stylePath(modules, margin, style) {
  const g = createElementSVG("g", { id: "points" });
  const paths = getPaths(modules)
  g.append(path_styles[style](paths, margin))
  return g
}

function styleData(modules, margin, style) {
  if (point_styles[style]) return stylePoints(modules, margin, style)
  else if (path_styles[style]) return stylePath(modules, margin, style)
  return stylePath(modules, margin, 'default')
}

function generateQRImage(layer, data) {
  if(!layer.qr) throw('Spork: invalid qr settings data')
  const margin = layer.qr.margin || 0;
  const qr = qrcodegen.QrCode.encodeText(data.content, errCorLvl);
  removeQRfinders(qr.modules, qr.size);

  const svg = createElementSVG("svg", {
    "shape-rendering": "crispEdges",
    preserveAspectRatio: "xMinYMin meet",
  });
  const val = qr.size + margin * 2;
  svg.setAttribute("viewBox", "0 0 " + val + " " + val);

  const bg = createElementSVG("rect", { width: "100%", height: "100%" });
  svg.appendChild(bg);

  svg.appendChild(
    getFinders(
      qr.size,
      margin,
      layer.qr.style?.finder_frame,
      layer.qr.style?.finder_eye
    )
  );
  svg.appendChild(
    styleData(qr.modules, margin, layer.qr.style?.point)
  );

  if (layer.qr.tag?.attach && (data.tag || (layer.qr.tag.prefix && data.prefix))) {
    attachTag(layer, svg, data);
  }
  const g = createElementSVG("g", { id: "qr" });
  g.appendChild(svg);
  return g;
}

function attachTag(layer, svg, data) {
  let str = "";

  if (layer.qr.tag.prefix && data.prefix) {
    if (layer.qr.tag.prefix_short)
      str = data.prefix
        .split(" ")
        .map((x) => x[0])
        .join("");
    else str = data.prefix;
  }
  if (data.tag) str += " " + data.tag;

  const viewBox = svg.viewBox.baseVal;

  const text_x = viewBox.width - (layer.qr.margin || 2) / 2;
  const text_y = viewBox.height;

  const font_size = (viewBox.height * layer.qr.tag.font_size) / 100; // 7% of the qr size
  const h = viewBox.height + font_size;
  svg.setAttribute("viewBox", "0 0 " + viewBox.width + " " + h);

  const t = createElementSVG("text");
  t.setAttribute("font-size", font_size);

  // set the text at the bottom right corner
  t.setAttribute("x", text_x);
  t.setAttribute("y", text_y);

  // using the top right corner of the text as ref
  t.setAttribute("alignment-baseline", "hanging");
  t.setAttribute("text-anchor", "end");

  t.appendChild(document.createTextNode(str));
  svg.appendChild(t);

  return svg;
}

function setQRcolor(layer, svg) {
  const bg = svg.firstChild;
  const color = layer.color || { stroke: "#000000", fillOpacity: 0 }
  bg.setAttribute("fill", color.fillOpacity == 0 ? 'none' : color.fill);
  bg.setAttribute("fill-opacity", color.fillOpacity);
  for (let i = 1; i < svg.children.length; i++)
    svg.children[i].setAttribute("fill", color.stroke);
}

function qrFinderStyleIcons(isFrame, size) {
  const finder_styles = isFrame ? finder_frame_styles : finder_eye_styles;
  const offset = isFrame ? 1 : 3;
  const icons = {};
  for (const key of Object.keys(finder_styles)) {
    const attr = size ? { width: size, height: size } : {}
    attr.viewBox = '0 0 9 9'
    const svg = createElementSVG("svg", attr)
    const title = createElementSVG("title");
    title.textContent = key;
    svg.appendChild(title);
    svg.dataset.idx = key;
    const g = finder_styles[key]();
    g.setAttribute("transform", "translate(" + offset + " " + offset + ")");
    svg.appendChild(g);
    icons[key] = svg.outerHTML;
  }
  return icons;
}

function qrPointStyleIcons(qr_size = 8, qr_margin = 2, img_size) {
  const cloneGrid = (grid) => [...grid].map(row => [...row])
  // generate random data
  const random_data = [];
  for (let i = 0; i < qr_size; i++)
    random_data.push([...Array(qr_size)].map((_) => Math.random() < 0.5));

  const size = qr_size + qr_margin * 2;
  const attr = img_size ? { width: img_size, height: img_size } : { }
  attr.viewBox = `0 0 ${size} ${size}`

  const icons = {};
  for (const key of [...Object.keys(path_styles), ...Object.keys(point_styles)]) {
    // make a copy of the data for path_styles, because it gets fucked up in processing
    const iconData = path_styles[key] ? cloneGrid(random_data) : random_data

    const svg = createElementSVG("svg", attr)
    const title = createElementSVG("title");
    title.textContent = key;
    svg.appendChild(title);
    svg.dataset.idx = key;

    svg.appendChild(styleData(iconData, qr_margin, key));

    icons[key] = svg.outerHTML;
  }
  return icons;
}
function qrStyleIcons(qr_size = 8, qr_margin = 2, img_size = 64) {
  return {
    finder_frame: qrFinderStyleIcons(true, img_size),
    finder_eye: qrFinderStyleIcons(false, img_size),
    point: qrPointStyleIcons(qr_size, qr_margin, img_size),
  };
}

export { generateQRImage, qrStyleIcons, setQRcolor };
