import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      subscription: 'billing/subscription',
      menus: 'menuManagement/menus',
      isSubscriptionInactive: 'billing/isSubscriptionInactive',
      isSubscriptionActive: 'billing/isSubscriptionActive',
      isSubscriptionCanceled: 'billing/isSubscriptionCanceled',
    }),
    isCreateMenuDisabledWithoutPlan() {
      return this.isSubscriptionInactive && this.menus.length >= 1
    },
    isCreateMenuDisabledWithPlan() {
      return this.subscription
        && (this.isSubscriptionActive || this.isSubscriptionCanceled)
        && (this.subscription.productDetails.name === 'Basic' || this.subscription.productDetails.name === 'Standard')
        && this.menus.length >= this.subscription?.productDetails?.restrictions?.menusPerRestaurantAmount
    },
  },
}
