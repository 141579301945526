<template>
  <svg
    class="icon-custom"
    width="14"
    height="14"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.75017 0.5C6.16438 0.5 6.50017 0.835786 6.50017 1.25V2H8.54923C8.55806 1.99984 8.56692 1.99984 8.5758 2H10.2502C10.6644 2 11.0002 2.33579 11.0002 2.75C11.0002 3.16421 10.6644 3.5 10.2502 3.5H9.18358C8.75996 5.47669 7.92675 7.30092 6.77723 8.87966L7.16181 9.46139C7.37552 9.78466 7.30776 10.2175 7.00549 10.46C6.70322 10.7025 6.26597 10.6748 5.99674 10.3961C5.91339 10.3098 5.83118 10.2225 5.75012 10.1342C4.57148 11.4182 3.16048 12.4851 1.58388 13.2684C1.21293 13.4527 0.762804 13.3014 0.5785 12.9305C0.394195 12.5595 0.545501 12.1094 0.916452 11.9251C2.3909 11.1925 3.70316 10.1829 4.78537 8.96464C4.11769 8.0614 3.55771 7.08069 3.11842 6.04218C2.96745 5.68528 3.11324 5.27213 3.45477 5.08901C3.7963 4.9059 4.2211 5.01312 4.43481 5.33639L5.88688 7.53287C6.69166 6.3181 7.29381 4.95768 7.6453 3.5H1.25017C0.835952 3.5 0.500166 3.16421 0.500166 2.75C0.500166 2.33579 0.835952 2 1.25017 2H5.00017V1.25C5.00017 0.835786 5.33595 0.5 5.75017 0.5ZM11.0002 6.5C11.2842 6.5 11.5439 6.6605 11.671 6.91459L14.2892 12.1511C14.2941 12.1603 14.2988 12.1697 14.3033 12.1791L15.421 14.4146C15.5372 14.6471 15.5248 14.9232 15.3882 15.1443C15.2515 15.3654 15.0101 15.5 14.7502 15.5H7.25017C6.99023 15.5 6.74883 15.3654 6.61218 15.1443C6.47552 14.9232 6.4631 14.6471 6.57935 14.4146L7.69708 12.1791C7.70156 12.1697 7.70623 12.1603 7.71109 12.1511L10.3293 6.91459C10.4564 6.6605 10.7161 6.5 11.0002 6.5ZM8.83869 13.25L8.46369 14H13.5366L13.1616 13.25H8.83869ZM12.4116 11.75H9.58869L11.0002 8.92705L12.4116 11.75Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconTranslate',
}
</script>
