<template>
  <div class="icon-move-vertical">
    <feather-icon
      size="20"
      icon="MoreVerticalIcon"
    />
    <feather-icon
      size="20"
      icon="MoreVerticalIcon"
    />
  </div>
</template>

<script>
export default {
  name: 'IconMoveVertical',
}
</script>

<style lang="sass">
  .icon-move-vertical
    flex-shrink: 0
    width: 14px
    height: 20px
    position: relative
    .feather-more-vertical
      position: absolute
      top: 0
      left: 0
      opacity: 0.5
      &:first-child
        left: -6px
</style>
