import { mapGetters } from 'vuex'
import { getFormattedPrice } from '@/formatter'

export default {
  computed: {
    ...mapGetters({
      locale: 'main/locale',
    }),
  },
  methods: {
    getFormattedPrice(value) {
      return getFormattedPrice(value)
    },
    getFormattedDate(timestamp) {
      const date = new Date(timestamp * 1000)

      const formatter = new Intl.DateTimeFormat(
        this.locale,
        {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        },
      )

      return formatter.format(date)
    },
  },
}
