import { createElementSVG } from './utils'

// for the transfrom image to work, "el" must be a <g>
// with an <svg> as first child and possibly the handles as second
export function transformImage(element, tr, destination) {
  const el = element
  const parent = el.closest('svg#canvas') || el.closest('svg#print')
  const bbox = parent.viewBox.baseVal
  const H = bbox.height
  const W = bbox.width
  const aspectBbox = H / W

  const elH = el.firstElementChild.viewBox.baseVal.height
  const elW = el.firstElementChild.viewBox.baseVal.width
  if (!(H && W && elH && elW)) return
  const aspectVbox = elH / elW

  const imageTall = aspectVbox > aspectBbox

  let h = H
  let w = W

  // Image is tall
  if (imageTall) w = h / aspectVbox
  else h = w * aspectVbox

  let scale = (tr.scale || 100) / 100

  if (tr.fillNotFit) scale *= imageTall ? W / w : H / h
  h *= scale
  w *= scale

  const x = (tr.x * W) / 100 + (W - w) / 2
  const y = (tr.y * H) / 100 + (H - h) / 2
  const cx = w / (2 * scale)
  const cy = h / (2 * scale)

  let transform = ' '

  if (x || y) transform += `translate(${x} ${y}) `
  if (scale) transform += `scale(${scale}) `
  if (tr.angle) transform += `rotate(${tr.angle} ${cx} ${cy})`

  el.setAttribute('transform', transform)

  if (destination === 'preview') {
    const k = parent.dataset.smallerSize / (scale * 400)
    el.dataset.outlineW = Math.ceil(k)

    // adjust handles radius and outline width
    const handles = el.querySelector('g#handle')
    if (handles) {
      handles.querySelectorAll('circle').forEach(handle => handle.setAttribute('r', 2.5 * k))

      const r = handles.querySelector('rect')
      r.setAttribute('stroke-width', 0.75 * k)
      r.setAttribute('stroke-dasharray', `${k * 2} ${k * 4}`)
    }
  }
}

export function setSelectionHandles(handles, element) {
  const LT = 0 // Left Top
  const RT = 1
  const LB = 2
  const RB = 3
  const ROTATE = 4
  const RECT = 5

  const bbox = element.getBBox()

  Array.from(handles.children).forEach((handle, i) => {
    switch (i) {
      case LT:
        handle.setAttribute('cx', bbox.x)
        handle.setAttribute('cy', bbox.y)
        break
      case RT:
        handle.setAttribute('cx', bbox.x + bbox.width)
        handle.setAttribute('cy', bbox.y)
        break
      case LB:
        handle.setAttribute('cx', bbox.x)
        handle.setAttribute('cy', bbox.y + bbox.height)
        break
      case RB:
        handle.setAttribute('cx', bbox.x + bbox.width)
        handle.setAttribute('cy', bbox.y + bbox.height)
        break
      case ROTATE:
        handle.setAttribute('cx', bbox.x + bbox.width / 2)
        handle.setAttribute('cy', bbox.y - bbox.height / 5)
        break
      case RECT:
        handle.setAttribute('x', bbox.x)
        handle.setAttribute('y', bbox.y)
        handle.setAttribute('width', bbox.width)
        handle.setAttribute('height', bbox.height)
        break
      default: break
    }
  })
}

function createSelectionHandles() {
  const group = createElementSVG('g', {
    id: 'handle',
  })

  group.appendChild(
    createElementSVG('circle', { class: 'handlePoint', cursor: 'nwse-resize' }),
  )
  group.appendChild(
    createElementSVG('circle', {
      class: 'handlePoint',
      cursor: 'nesw-resize',
    }),
  )
  group.appendChild(
    createElementSVG('circle', {
      class: 'handlePoint',
      cursor: 'nesw-resize',
    }),
  )
  group.appendChild(
    createElementSVG('circle', {
      class: 'handlePoint',
      cursor: 'nwse-resize',
    }),
  )
  group.appendChild(
    createElementSVG('circle', {
      id: 'rotateHandle',
      class: 'handlePoint',
      cursor: 'e-resize',
    }),
  )

  group.appendChild(
    createElementSVG('rect', {
      fill: 'none',
    }),
  )
  return group
}

// because the fucking Firefox cannot get right the Bounding Rect for an
// inner SVG, we need to use handles' coordinates to find the SVG center
function getCenter(handles) {
  return [
    (handles.children[0].getBoundingClientRect().x
      + handles.children[3].getBoundingClientRect().x)
    / 2,

    (handles.children[0].getBoundingClientRect().y
      + handles.children[3].getBoundingClientRect().y)
    / 2,
  ]
}

export function draggable(options) {
  const opt = options
  const { selected } = opt
  let startX; let
    startY
  let startScaleY; let startScaleX; let cx; let
    cy

  let target

  const initial = { x: null, y: null, scale: null }
  const parentRect = selected.el.parentElement.getBoundingClientRect()

  const { transform } = selected.layer
  const abortController = new AbortController()

  const handles = createSelectionHandles()
  setSelectionHandles(handles, selected.el)
  selected.el.appendChild(handles)

  // the only reason why need to run transform at the moment
  // of the first click, is to get the radius for the handles
  transformImage(selected.el, transform, 'preview')

  function handleMouseMove(event) {
    event.stopImmediatePropagation()

    // move
    if (target === selected.el) {
      const dx = (event.clientX - startX) * (100 / parentRect.width)
      const dy = (event.clientY - startY) * (100 / parentRect.height)

      transform.x = initial.x + Math.floor(dx)
      transform.y = initial.y + Math.floor(dy)

      // rotate
    } else if (target.id === 'rotateHandle') {
      const vectorX = event.clientX - cx
      const vectorY = event.clientY - cy
      const angle = (Math.atan2(vectorY, vectorX) * 180) / Math.PI

      transform.angle = Math.round(angle + 90)

      // scale
    } else {
      const scaleX = event.clientX - cx
      const scaleY = event.clientY - cy
      const dx = Math.abs(scaleX)
      const dy = Math.abs(scaleY)
      const scaleK = dx > dy ? dx / startScaleX : dy / startScaleY
      transform.scale = Math.round(initial.scale * scaleK)
    }

    transformImage(selected.el, transform, 'preview')
    opt.isSaved = false
  }

  function handleMouseDown(event) {
    event.stopImmediatePropagation()
    target = event.currentTarget

    // move
    startX = event.clientX
    startY = event.clientY

    initial.x = transform.x
    initial.y = transform.y;

    // rotate
    [cx, cy] = getCenter(handles)

    // scale
    startScaleY = Math.abs(event.clientY - cy)
    startScaleX = Math.abs(event.clientX - cx)
    initial.scale = selected.layer.transform.scale

    document.body.addEventListener('mousemove', handleMouseMove, false)
    document.body.addEventListener('mouseup', handleMouseUp, { once: true }) // eslint-disable-line
  }

  function handleMouseUp(event) {
    event.stopImmediatePropagation()
    document.body.removeEventListener('mousemove', handleMouseMove, false)
    document.body.removeEventListener('mouseup', handleMouseUp, false)
    target.addEventListener('mousedown', handleMouseDown, {
      once: true,
      signal: abortController.signal,
    })
  }

  selected.el.addEventListener('mousedown', handleMouseDown, {
    once: true,
    signal: abortController.signal,
  })

  handles.childNodes.forEach(handle => {
    handle.addEventListener('mousedown', handleMouseDown, {
      once: true,
      signal: abortController.signal,
    })
  })
  return abortController
}
