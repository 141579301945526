<template>
  <b-row>
    <b-col>
      <div class="d-flex align-items-center">
        <icon-move-vertical class="card-content__transparent mr-1" />
        <validation-provider
          #default="{ errors }"
          class="flex-grow-1"
          name="add_text"
          :vid="item.id"
          :rules="`max:${textLimit}`"
        >
          <textarea-counter
            size="sm"
            :disabled="!isContentValid && !errors.length"
            :placeholder="`${$t('Text')}`"
            :is-error="!!errors.length"
            :text-limit="textLimit"
            :value="item.value"
            @input="$emit('textarea-input', $event)"
            @blur="$emit('textarea-blur')"
          />
        </validation-provider>
      </div>
    </b-col>
    <b-col
      cols="auto"
      class="d-flex align-items-center"
    >
      <feather-icon
        :class="['card-content__transparent', { 'feather-disabled': !isContentValid }]"
        role="button"
        size="20"
        icon="TrashIcon"
        @click="$emit('delete')"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'

import TextareaCounter from '@/components/TextareaCounter.vue'
import IconMoveVertical from '@/components/icon/IconMoveVertical.vue'

import { ValidationProvider } from 'vee-validate'
import { max } from '@validations'

export default {
  name: 'MenuContentText',
  components: {
    BRow,
    BCol,

    TextareaCounter,
    IconMoveVertical,

    ValidationProvider,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({ id: '', type: 'Text', value: '' }),
    },
    textLimit: {
      type: Number,
      required: true,
      default: 250,
    },
    isContentValid: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      // validation rules
      max,
    }
  },
}
</script>
